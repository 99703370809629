import React, { useEffect, useRef } from 'react';
import SectionGridFeaturePlaces from './SectionGridFeaturePlaces';
import SectionHowItWork from 'components/SectionHowItWork/SectionHowItWork';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import { TcsProductType } from 'data/types';
import Slider from 'react-slick';
import { POST_BILLER } from 'utils/apiHelper';
import { getStorage, getTitleWebsite, getMerchantKey } from 'utils/localStorage';
import { CONFIG_STR } from 'contains/contants';
import convertNumbThousand from 'utils/convertNumbThousand';
import { encode } from 'js-base64';
import Heading from 'components/Heading/Heading';
import { Helmet } from 'react-helmet';
import checkFileExt from 'utils/checkFileExt';
import { message } from 'utils/message';
import { useHistory } from 'react-router-dom';
import OnlyOneProductView from './OnlyOneProductView';

function PageHome3() {
  const [tcsProduct, setTcsProduct] = React.useState<TcsProductType[]>([]);
  const [tcsEvent, setTcsEvent] = React.useState<TcsProductType[]>([]);
  const [config, setConfig] = React.useState<any>(null);
  const [selectedDay, setSelectedDay] = React.useState<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [openFocusIndex, setOpenFocusIndex] = React.useState(0);
  const [passenger, setPassenger] = React.useState<number>(0);
  const [openPassenger, setOpenPassenger] = React.useState(false);
  const [isPersonal, setPersonal] = React.useState(true);
  const wrapperRef = useRef<any>(null);
  const router = useHistory();

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenPassenger(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef])

  // CUSTOM THEME STYLE
  useEffect(() => {
    const $body = document.querySelector('body');
    if (!$body) return;
    $body.classList.add('theme-purple-blueGrey');
    return () => {
      $body.classList.remove('theme-purple-blueGrey');
    };
  }, []);

  React.useEffect(() => {
    setPassenger(0);
  }, [isPersonal])

  React.useEffect(() => {
    getInformationTcsProduct();
    getInformationTcsEvent();
  }, []);

  React.useEffect(() => {
    if (!config) {
      let config = getStorage(CONFIG_STR);
      if (config) {
        setConfig(config);
      }
    }
  }, [config]);

  const getInformationTcsProduct = () => {
    let config = getStorage(CONFIG_STR);
    if (config) {
      let body = {
        billerMerchantKey: getMerchantKey() ? getMerchantKey() : "",
        draw: 1,
        limit: 8
      };
      POST_BILLER('/public/biller-merchant-tcs/list/index', body)
        .then((tcs) => {
          let product: TcsProductType[] = [];
          if (tcs.result.listData?.length > 0) {
            tcs.result.listData?.map((item: any) => {
              product.push({
                id: item.id,
                cid: item.cid,
                href: '/listing-stay-detail?key=' + encode(item.cid),
                title: item.name,
                description: item.tcsConfig.description,
                featuredImage: item.imageThumbnail,
                address: item.cityName + ', ' + item.provinceName,
                like: item.rating,
                galleryImgs:
                  item.tcsConfig.imageGallery?.length > 0
                    ? item.tcsConfig.imageGallery
                    : [
                      'https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg',
                    ],
                price:
                  convertNumbThousand(item.minPrice) +
                  ' - ' +
                  convertNumbThousand(item.maxPrice),
                embedMap: item.tcsConfig.embedMap,
                ...item
              });
            });
            setTcsProduct(product);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getInformationTcsEvent = () => {
    let config = getStorage(CONFIG_STR);
    if (config) {
      let body = {
        billerMerchantKey: getMerchantKey() ? getMerchantKey() : "",
        eventCode: "",
        draw: 1,
        limit: 8,
        offset: 0
      };
      POST_BILLER('/public/biller-merchant-event/list-bydate', body)
        .then((tcs) => {
          let product: TcsProductType[] = [];
          if (tcs.result.listData?.length > 0) {
            tcs.result.listData?.map((item: any) => {
              product.push({
                id: item.id,
                cid: item.cid,
                href:
                  '/listing-event-detail?key=' + item.eventCode,
                title: item.eventName,
                description: item.eventDescription,
                featuredImage: item.imageThumbnail,
                address: item.eventCity + ', ' + item.eventProvince,
                like: item?.rating,
                galleryImgs:
                  item.imageGallery?.length > 0
                    ? item.imageGallery
                    : [
                      'https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg',
                    ],
                price: '',
                embedMap: item.embedMap,
              });
            });
            setTcsEvent(product);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const settings = {
    dots: true,
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1500,
  };

  if (!tcsProduct) {
    return null;
  }
  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        {/* <div className="flex justify-between items-center">
          <Badge name={tcsProduct.tcsCategoryName} />
          <LikeSaveBtns />
        </div> */}

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {tcsProduct?.[0]?.title}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {/* <StartRating
            point={tcsProduct?.like}
            reviewCount={100}
          /> */}
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">
              {' '}
              {tcsProduct?.[0]?.address}
            </span>
          </span>
        </div>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" la la-money text-2xl "></i>
            <span className="">
              <span className="inline-block">
                {tcsProduct?.[0]?.price}
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Place information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span
            dangerouslySetInnerHTML={{
              __html: tcsProduct?.[0]?.description,
            }}
          />
        </div>
      </div>
    );
  };

  const handleDecrement = () => {
    if (passenger !== 0) {
      setPassenger((value) => Number(value) - 1)
    }
  }

  const handleIncrement = () => {
    if (isPersonal) {
      if (passenger !== 10) {
        setPassenger((value) => Number(value) + 1)
      }
    } else {
      setPassenger((value) => Number(value) + 1)
    }
  }

  const handleToCheckout = () => {
    if (!selectedDay || passenger === 0) {
      message('error', 'Please fill field correctly');
    } else {
      let item = tcsProduct?.[0];
      let dataTcs = {
        date: `${selectedDay?.year}-${selectedDay?.month < 10 ? "0" + selectedDay?.month : selectedDay?.month}-${selectedDay?.day < 10 ? "0" + selectedDay?.day : selectedDay?.day}`,
        person: passenger,
        cid: item?.cid,
        merchantKey: item?.merchantKey,
        name: item?.name,
        tcVendorCode: item?.tcsConfig?.tcVendorCode,
        hirarki: item?.hirarki,
      };
      let dataToEncode = encode(JSON.stringify(dataTcs));
      router.push('/reservation', {
        data: dataToEncode,
      });
    }
  };

  const handleToGroupCheckout = () => {
    if (!selectedDay || passenger === 0) {
      message('error', 'Please fill field correctly');
    } else {
      let item = tcsProduct?.[0];
      let dataTcs = {
        date: `${selectedDay?.from?.year}-${selectedDay?.from?.month < 10 ? "0" + selectedDay?.from?.month : selectedDay?.from?.month}-${selectedDay?.from?.day < 10 ? "0" + selectedDay?.from?.day : selectedDay?.from?.day}`,
        dateFrom: `${selectedDay?.from?.year}-${selectedDay?.from?.month < 10 ? "0" + selectedDay?.from?.month : selectedDay?.from?.month}-${selectedDay?.from?.day < 10 ? "0" + selectedDay?.from?.day : selectedDay?.from?.day}`,
        dateTo: `${selectedDay?.to?.year}-${selectedDay?.to?.month < 10 ? "0" + selectedDay?.to?.month : selectedDay?.to?.month}-${selectedDay?.to?.day < 10 ? "0" + selectedDay?.to?.day : selectedDay?.to?.day}`,
        person: passenger,
        cid: item?.cid,
        merchantKey: item?.merchantKey,
        name: item?.name,
        tcVendorCode: item?.tcsConfig?.tcVendorCode,
        hirarki: item?.hirarki,
      };
      let dataToEncode = encode(JSON.stringify(dataTcs));
      router.push('/group-reservation', {
        data: dataToEncode,
      });
    }
  };

  const renderCustomInput = ({ ref }: any) => {
    return (
      <div className="flex w-full flex-row items-center gap-2 cursor-pointer">
        <i className="las la-calendar-alt text-[30px]"></i>
        <div className="flex flex-col justify-between gap-1 w-full">
          <div className="text-base text-black font-bold">Tanggal Tiket</div>
          <input
            readOnly
            ref={ref}
            placeholder="Silahkan pilih tanggal"
            value={selectedDay ? `${selectedDay?.year}-${selectedDay?.month < 10 ? "0" + selectedDay?.month : selectedDay?.month}-${selectedDay?.day < 10 ? "0" + selectedDay?.day : selectedDay?.day}` : ""}
            className="text-sm text-gray-400 font-thin w-full md:w-80 outline-none"
          />
        </div>
      </div>
    )
  }

  const renderCustomRangeInput = ({ ref }: any) => {
    return (
      <div className="flex w-full flex-row items-center gap-2 cursor-pointer">
        <i className="las la-calendar-alt text-[30px]"></i>
        <div className="flex flex-col justify-between gap-1 w-full">
          <div className="text-base text-black font-bold">Tanggal Tiket</div>
          <input
            readOnly
            ref={ref}
            placeholder="Silahkan pilih tanggal"
            value={selectedDay ?
              `${selectedDay?.from?.year}-${selectedDay?.from?.month < 10 ? "0" + selectedDay?.from?.month : selectedDay?.from?.month}-${selectedDay?.from?.day < 10 ? "0" + selectedDay?.from?.day : selectedDay?.from?.day} - ${selectedDay?.to?.year || "YYYY"}-${selectedDay?.to?.month < 10 ? "0" + selectedDay?.to?.month || "MM" : selectedDay?.to?.month || "MM"}-${selectedDay?.to?.day < 10 ? "0" + selectedDay?.to?.day || "DD" : selectedDay?.to?.day || "DD"}` : ""}
            className="text-sm text-gray-400 font-thin w-full md:w-80 outline-none"
          />
        </div>
      </div>
    )
  }

  if (tcsProduct.length === 1) {
    return (
      <OnlyOneProductView
        config={config}
        handleCloseModal={handleCloseModal}
        handleDecrement={handleDecrement}
        handleIncrement={handleIncrement}
        handleOpenModal={handleOpenModal}
        handleToCheckout={handleToCheckout}
        isOpen={isOpen}
        openFocusIndex={openFocusIndex}
        openPassenger={openPassenger}
        passenger={passenger}
        renderCustomInput={renderCustomInput}
        renderCustomRangeInput={renderCustomRangeInput}
        renderSection1={renderSection1}
        renderSection2={renderSection2}
        selectedDay={selectedDay}
        setOpenPassenger={setOpenPassenger}
        setSelectedDay={setSelectedDay}
        settings={settings}
        tcsEvent={tcsEvent}
        tcsProduct={tcsProduct}
        wrapperRef={wrapperRef}
        handleToGroupCheckout={handleToGroupCheckout}
        isPersonal={isPersonal}
        setPersonal={setPersonal}
        setPassenger={setPassenger}
      />
    )
  }
  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      <Helmet>
        <title>{getTitleWebsite()}</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <Slider {...settings}>
        {config?.imageBanner.map((item: any) => {
          const fileType = checkFileExt(item);

          if (fileType === 'image') {
            return (
              <img
                src={item}
                alt=""
                className="object-fill h-[35vh] md:h-[50vh] lg:h-[80vh]"
              />
            );
          } else if (fileType === 'video') {
            return (
              <div>
                <video
                  width="100%"
                  height="auto"
                  autoPlay={true}
                  muted={true}
                  loop={true}
                  playsInline={true}
                  id="myVideo"
                  data-ll-status="loaded"
                >
                  <source data-src={item} src={item} />
                </video>
              </div>
            );
          }

          return <></>;
        })}
      </Slider>
      <div className="container relative space-y-24 mb-24 mt-0 sm:mt-10">
        {/* SECTION */}
        <div className="relative py-8 md:py-16" style={{
          marginTop: "1rem"
        }}>
          <BackgroundSection />
          <SectionGridFeaturePlaces
            stayListings={tcsProduct.slice(0, 4)}
            tcsEvent={tcsEvent.slice(0, 4)}
          />
        </div>
        <SectionHowItWork />
        <div>
          <Heading isCenter desc="">
            Tentang Kami
          </Heading>
          <span className="block text-neutral-500 dark:text-neutral-400 text-center">
            {config?.about}
          </span>
        </div>
        {/* SECTION */}
        {/* <SectionSubscribe2 /> */}
      </div>
    </div>
  );
}

export default PageHome3;
