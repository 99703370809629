import React, { FC, useEffect } from 'react';
import facebookSvg from 'images/Facebook.svg';
import twitterSvg from 'images/Twitter.svg';
import googleSvg from 'images/Google.svg';
import { Helmet } from 'react-helmet';
import Input from 'shared/Input/Input';
import { Link } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Title from '../../data/title';
import {
  isLogin,
  setLogin,
  setEmailLocalStorage,
  getStorage,
  setStorageLocalStorage,
  setUserStorage,
  getTitleWebsite,
} from 'utils/localStorage';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { POST } from 'utils/apiHelper';
import { message } from 'utils/message';
import { CONFIG_STR, CONFIG_TCS } from 'contains/contants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = '' }) => {
  const [disableBtn, setDisableBtn] = React.useState(false);
  const [securePass, setSecurePass] = React.useState(true);
  const [websiteConfig, setWebsiteConfig] = React.useState<any>(null);
  const dekstop = window.matchMedia('screen and (min-width: 768px)').matches
  const location: any = useHistory();
  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
      cid: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .max(20, 'Maksimal 20 karakter!')
        .required('Required'),
      email: Yup.string().email('Email tidak valid! ').required('Required'),
      // cid: Yup.string().min(1).required('Required')
    }),
    onSubmit: (values) => {
      handleLogin(values);
    },
  });
  React.useEffect(() => {
    if (isLogin()) {
      location.push('/');
    }
  }, []);
  React.useEffect(() => {
    if (!websiteConfig) {
      let config = getStorage(CONFIG_STR);
      setWebsiteConfig(config);
    }
  }, [websiteConfig]);
  const handleLogin = async (body: any) => {
    setDisableBtn(true);
    if (websiteConfig) {
      let data = {
        email: body.email,
        password: body.password,
        cid: websiteConfig?.isB2BPortal ? body.cid : websiteConfig?.cid,
      };
      try {
        let res = await POST('/customers/loginv2', data);
        if (res) {
          if (res.success) {
            if (res.result) {
              setEmailLocalStorage(res.result.email);
              setUserStorage(res.result);
              setLogin(res.result.token);
              POST('/tcs-config/list/index', {
                cid: websiteConfig?.isB2BPortal ? res.result.cid : websiteConfig?.cid,
                hirarki: '',
              }).then((cfg) => {
                if (cfg.result.length !== 0) {
                  setStorageLocalStorage(CONFIG_TCS, cfg.result[0]);
                }
                message('success', 'Login success');
                if (location.location.pathname === '/login') {
                  setTimeout(() => {
                    location.push('/');
                  }, 1000);
                } else {
                  setTimeout(() => {
                    let pathname = `${location.location.pathname}${location.location.search ? location.location.search : ''
                      }`;
                    location.push(pathname, {
                      data: location?.location?.state?.data,
                    });
                    setDisableBtn(false);
                  }, 1000);
                }
              });
            }
          } else {
            message('error', res.message);
            setTimeout(() => {
              setDisableBtn(false);
            }, 5000);
          }
        }
      } catch (error) {
        message('error', error);
        setTimeout(() => {
          setDisableBtn(false);
        }, 5000);
      }
    }
  };

  const handleLoginWithGoogle = async (body: any) => {
    setDisableBtn(true);
    if (websiteConfig) {
      let data = {
        email: body.email,
        password: body.password,
        name: body?.name
      };
      try {
        let res = await POST('/logingoogle/signin', data);
        if (res) {
          if (res.success) {
            if (res.result) {
              setEmailLocalStorage(res.result.email);
              setUserStorage(res.result);
              setLogin(res.result.token);
              POST('/tcs-config/list/index', {
                cid: websiteConfig?.isB2BPortal ? res.result.cid : websiteConfig?.cid,
                hirarki: '',
              }).then((cfg) => {
                if (cfg.result.length !== 0) {
                  setStorageLocalStorage(CONFIG_TCS, cfg.result[0]);
                }
                message('success', 'Login success');
                if (location.location.pathname === '/login') {
                  setTimeout(() => {
                    location.push('/');
                  }, 1000);
                } else {
                  setTimeout(() => {
                    let pathname = `${location.location.pathname}${location.location.search ? location.location.search : ''
                      }`;
                    location.push(pathname, {
                      data: location?.location?.state?.data,
                    });
                    setDisableBtn(false);
                  }, 1000);
                }
              });
            }
          } else {
            message('error', res.message);
            setTimeout(() => {
              setDisableBtn(false);
            }, 5000);
          }
        }
      } catch (error) {
        message('error', error);
        setTimeout(() => {
          setDisableBtn(false);
        }, 5000);
      }
    }
  };

  const responseMessage = (response: any) => {
    const decoded: any = jwtDecode(response?.credential);
    handleLoginWithGoogle({
      email: decoded?.email,
      password: '999999',
      name: decoded?.name
    })
  };
  const errorMessage = () => {
    message('error', 'Google signin error!');
  };


  if (websiteConfig?.isSigninGoogle) {
    return (
      <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
        <Helmet>
          <title>{getTitleWebsite()} - Login</title>
        </Helmet>
        <div className="container mb-24 lg:mb-32">
          <h2 className="my-10 font-bold flex items-center text-xl text-center leading-[115%] md:text-xl md:leading-[115%] text-neutral-900 dark:text-neutral-100 justify-center">
            Masuk
          </h2>
        </div>
        <div className='w-full flex h-[15rem] justify-center'>
          <GoogleLogin
            size="large"
            shape="circle"
            width={dekstop ? '500px' : ''}
            onSuccess={responseMessage}
            onError={errorMessage}
          />
        </div>
      </div>
    )
  }
  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>{getTitleWebsite()} - Login</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <h2 className="my-10 font-bold flex items-center text-xl text-center leading-[115%] md:text-xl md:leading-[115%] text-neutral-900 dark:text-neutral-100 justify-center">
          Masuk
        </h2>
        <div className="w-full md:w-1/3 mx-auto space-y-6">
          <form
            className="grid grid-cols-1 gap-6"
            onSubmit={formik.handleSubmit}
          >
            {
              websiteConfig?.isB2BPortal &&
              <label className="block">
                <span className="text-neutral-800 dark:text-neutral-200">
                  CID
                </span>
                <Input
                  type="text"
                  placeholder="Cid"
                  className="mt-1"
                  onChange={(e) => formik.setFieldValue('cid', e.target.value)}
                  onBlur={formik.handleBlur}
                  value={formik.values.cid}
                />
                {formik.touched.cid && formik.errors.cid ? (
                  <div className="text-red-400 text-sm mt-2">
                    {formik.errors.cid}
                  </div>
                ) : null}
              </label>
            }
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email
              </span>
              <Input
                type="text"
                placeholder="Email"
                className="mt-1"
                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-red-400 text-sm mt-2">
                  {formik.errors.email}
                </div>
              ) : null}
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm">
                  Lupa password?
                </Link>
              </span>
              <div className="relative">
                <Input
                  type={securePass ? 'password' : 'text'}
                  placeholder="Password"
                  className="mt-1"
                  onChange={(e) =>
                    formik.setFieldValue('password', e.target.value)
                  }
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                <div
                  onClick={() => setSecurePass(!securePass)}
                  className="cursor-pointer absolute right-0 top-0 bottom-0 mt-auto mb-auto text-center w-10 flex flex-row justify-center items-center"
                >
                  {securePass ? (
                    <i className="la la-eye-slash text-xl" />
                  ) : (
                    <i className="la la-eye text-xl" />
                  )}
                </div>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="text-red-400 text-sm mt-2">
                  {formik.errors.password}
                </div>
              ) : null}
            </label>
            <ButtonPrimary disabled={disableBtn} type="submit">
              Lanjut
            </ButtonPrimary>
          </form>

          {/* ==== */}
          {
            !websiteConfig?.isB2BPortal && <span className="block text-center text-neutral-700 dark:text-neutral-300">
              Pengguna baru? {` `}
              <Link to="/signup">Buat akun</Link>
            </span>
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(PageLogin);
