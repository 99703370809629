import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { shortString } from "utils/shortString";
import { Dialog, Transition } from '@headlessui/react';
import { message } from "utils/message";
import { POST } from "utils/apiHelper";
import { encode } from "js-base64";
import { useHistory } from "react-router-dom";
import ModalPhotos from "./ModalPhotos";
import convertNumbThousand from "utils/convertNumbThousand";

export interface TicketCardProps {
    className?: string;
    data?: any;
    date: string;
}

const TicketCard: FC<TicketCardProps> = ({ className = "", data, date }) => {
    const router: any = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const [isSlide, setIsSlide] = useState(false);
    const [isOpenGallery, setIsOpenGallery] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [person, setPerson] = React.useState<number>(0);
    const [loading, setLoading] = React.useState(false);
    const [disableBtn, setDisableBtn] = React.useState(false);

    useEffect(() => {
        let checkAvailableMin = Number(data?.productConfig?.minQty);
        let checkAvailableMax = Number(data?.productConfig?.maxQty);
        if (checkAvailableMax > 0) {
            if (person > checkAvailableMax || person < checkAvailableMin || person === 0) {
                setDisableBtn(true)
            } else {
                setDisableBtn(false)
            }
        } else {
            if (person < checkAvailableMin || person === 0) {
                setDisableBtn(true)
            } else {
                setDisableBtn(false)
            }
        }
    }, [person]);

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleDecrement = () => {
        if (person > 0) {
            setPerson(person - 1);
        }
    }

    const handleIncrement = () => {
        if (Number(data?.productConfig?.maxQty) === 0) {
            if (person < 999) {
                setPerson(person + 1);
            }
        } else {
            if (person < Number(data?.productConfig?.maxQty)) {
                setPerson(person + 1);
            }
        }
    }

    const handleOpenModal = () => {
        setIsOpenGallery(true);
    };

    const handleCloseModal = () => setIsOpenGallery(false);

    const handleToCheckout = () => {
        setDisableBtn(true);
        if (person === 0) {
            message('error', 'Please fill field correctly');
            setTimeout(() => {
                setDisableBtn(false);
            }, 5000);
        } else {
            setLoading(true)
            let body = {
                cid: data?.cid
            }
            POST("/mtcs/index", body).then((tcs) => {
                if (tcs.result) {
                    let tcsProduct = tcs.result;
                    let body = {
                        person: person,
                        cid: tcsProduct?.cid,
                        merchantKey: tcsProduct?.merchantKey,
                        tcVendorCode: tcsProduct?.tcVendorCode,
                        hirarki: tcsProduct?.hirarki,
                        date: date,
                        product: data,
                        name: data?.tcsName
                    };
                    let dataToEncode = encode(JSON.stringify(body));
                    router.push('/desa-reservation', {
                        data: dataToEncode,
                    });
                    setLoading(false);
                }
            }).catch((error) => {
                console.log(error);
                setLoading(false);
            });
        }
    };

    const renderDetailTop = () => {
        return (
            <div>
                <div className="flex flex-col md:flex-row items-center">
                    <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 hidden lg:block">
                        <img onClick={() => handleOpenModal()} src={data?.productConfig?.imageThumbnail?.[0]} className="w-20 h-20 object-contain" alt="" />
                    </div>
                    <div className="space-y-5 md:pl-24">
                        {/* <div className="border-t border-neutral-200 dark:border-neutral-700" /> */}
                        <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base" dangerouslySetInnerHTML={{ __html: data?.productConfig?.description }} />
                        {/* <div className="border-t border-neutral-200 dark:border-neutral-700" /> */}
                    </div>
                </div>
            </div>
        );
    };

    const renderDetail = () => {
        if (!isSlide) return null;
        return (
            <div className="p-4 md:p-8 bg-white border border-neutral-200 dark:border-neutral-700 rounded-b-lg mt-4">
                {renderDetailTop()}
            </div>
        );
    };

    return (
        <>
            <div className="bg-white h-auto flex flex-col shadow-lg rounded-lg p-4 pb-4 cursor-pointer hover:shadow-xl mb-4">
                <div className="flex flex-col lg:flex-row flex-1">
                    <div className="flex-[0.1]">
                        <img
                            src={data?.productConfig?.imageThumbnail?.[0]}
                            className='w-[80px] h-[80px] lg:w-full lg:h-full object-fill'
                        />
                    </div>
                    <div className="flex-[0.7] pl-0 lg:pl-4 flex flex-col mt-4 lg:mt-0 justify-between gap-2">
                        <div className="flex flex-col gap-1">
                            <span className="font-medium text-lg lg:text-xl">{data?.productName}</span>
                            <div className="flex flex-row gap-2 items-center">
                                <i className="las la-clock text-lg text-gray-500" />
                                <p className='text-gray-500 text-sm lg:text-[14px]'>Berlaku di tanggal terpilih</p>
                            </div>
                        </div>
                        <div className="w-[90%] border-b border-neutral-200 dark:border-neutral-700"></div>
                        <span className="font-semibold text-lg text-red-700">Rp {convertNumbThousand(data?.b2cPrice)}</span>
                    </div>
                    <div className="flex-[0.2] flex flex-row items-center pl-0 lg:pl-4 gap-0 lg:gap-2 justify-between lg:justify-start pt-4 lg:pt-0">
                        <ButtonPrimary
                            onClick={() => setOpenModal(true)}>Pesan Tiket
                        </ButtonPrimary>
                        <span
                            className={`w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isSlide ? "transform -rotate-180" : ""
                                }`}
                            onClick={() => setIsSlide(!isSlide)}
                        >
                            <i className="text-xl las la-angle-down"></i>
                        </span>
                    </div>
                </div>
                {renderDetail()}
            </div>
            <Transition appear show={openModal} as={React.Fragment}>
                <Dialog as="div" className="relative z-10" onClose={handleClose}>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={React.Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-center text-gray-900"
                                    >
                                        PILIH JUMLAH PENGUNJUNG
                                    </Dialog.Title>
                                    <div className="mt-10 flex flex-col w-full justify-center items-center">
                                        <div className="flex flex-row w-[50%] justify-between items-center align">
                                            <button onClick={() => handleDecrement()} className="flex flex-row w-10 h-10 rounded-full bg-blue-300 justify-center items-center">-</button>
                                            <span>{person}</span>
                                            <button onClick={() => handleIncrement()} className="flex flex-row w-10 h-10 rounded-full bg-blue-300 justify-center items-center">+</button>
                                        </div>
                                        <div className="flex flex-row justify-end items-center mt-10 w-full">
                                            <ButtonPrimary
                                                onClick={() => handleClose()}
                                                type="button"
                                                className="mr-2"
                                            >
                                                Batal
                                            </ButtonPrimary>
                                            <ButtonPrimary
                                                onClick={() => handleToCheckout()}
                                                type="button"
                                                loading={loading}
                                                disabled={disableBtn}
                                            >
                                                Konfirmasi
                                            </ButtonPrimary>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <ModalPhotos
                imgs={data?.productConfig?.imageGallery?.length > 0 ? data?.productConfig?.imageGallery : []}
                isOpen={isOpenGallery}
                onClose={handleCloseModal}
                initFocus={openFocusIndex}
                uniqueClassName="nc-ListingEventDetailPage-modalPhotos"
            />
        </>
    );
};

export default TicketCard;
