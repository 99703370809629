import StayDatesRangeInput from 'components/HeroSearchForm/StayDatesRangeInput';
import StartRating from 'components/StartRating/StartRating';
import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import { POST, POST_BILLER } from 'utils/apiHelper';
import convertNumbThousand from 'utils/convertNumbThousand';
import { getStorage, getTitleWebsite, getUserStorage } from 'utils/localStorage';
import MobileFooterSticky from './MobileFooterSticky';
import ModalPhotos from './ModalPhotos';
import { Helmet } from "react-helmet";
import { CONFIG_STR } from 'contains/contants';
import TicketCard from './TicketCard';
import moment from 'moment';
import CheckIsLogin from 'hooks/checkIsLogin';
import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
const momentPropTypes = require('react-moment-proptypes');

export interface ListingDetailDesaWisataProps {
    className?: string;
    isPreviewMode?: boolean;
}

const ListingDetailDesaWisata: FC<ListingDetailDesaWisataProps> = ({
    className = '',
    isPreviewMode,
}) => {
    const location = useLocation();
    const router = useHistory()
    const { isLoginUser } = CheckIsLogin()
    const [isOpen, setIsOpen] = useState(false);
    const [openFocusIndex, setOpenFocusIndex] = useState(0);
    const [tcsProduct, setTcsProduct] = useState<any>({});
    const [selectedDate, setSelectedDate] = useState<
        typeof momentPropTypes.momentObj | null
    >(null);
    const [currentPositionDate, setCurrentPositionDate] = useState<string>("");
    const [daysArr, setDaysArr] = React.useState<any>([]);
    const [ticket, setTicket] = React.useState<any>(null);
    const [openTnc, setOpenTnc] = React.useState(false);

    React.useEffect(() => {
        let config = getStorage(CONFIG_STR);
        setTimeout(() => {
            if (isLoginUser === false && config?.isB2BPortal) {
                router.replace("/login")
            }
        }, 1200)
    }, [isLoginUser]);

    React.useEffect(() => {
        if (Object.keys(tcsProduct).length === 0) {
            getDetailTcsProduct();
        }
    }, [tcsProduct]);

    React.useEffect(() => {
        if (!selectedDate && Object.keys(tcsProduct).length !== 0) {
            if (tcsProduct?.tcsConfig?.dateFrom && tcsProduct?.tcsConfig?.dateTo) {
                setSelectedDate(moment(tcsProduct?.tcsConfig?.dateFrom));
            } else {
                setSelectedDate(moment(Date.now()));
            }
        }
    }, [selectedDate, tcsProduct]);

    React.useEffect(() => {
        if (!ticket && currentPositionDate !== "") {
            getProductTcsInformation();
        }
    }, [ticket, currentPositionDate]);

    React.useEffect(() => {
        if (selectedDate && Object.keys(tcsProduct).length !== 0) {
            let maxDay = 12;
            let daysArrTemp = [];
            var beforeTwoWeeks = moment(selectedDate, "YYYY-MM-DD").add(maxDay, 'days');
            var today = moment(selectedDate).startOf('day');
            if (tcsProduct?.tcsConfig?.dateFrom && tcsProduct?.tcsConfig?.dateTo) {
                for (let m = moment(today); m.diff(moment(tcsProduct?.tcsConfig?.dateTo, "YYYY-MM-DD"), 'days') <= 0; m.add(1, 'days')) {
                    daysArrTemp.push(m.format('YYYY-MM-DD'));
                }
            } else {
                for (let m = moment(today); m.diff(beforeTwoWeeks, 'days') <= 0; m.add(1, 'days')) {
                    daysArrTemp.push(m.format('YYYY-MM-DD'));
                }
            }
            setDaysArr(daysArrTemp);
            setCurrentPositionDate(daysArrTemp[0]);
        }
    }, [selectedDate, tcsProduct]);

    const getProductTcsInformation = async () => {
        let config = getStorage(CONFIG_STR);
        let userConfig = getUserStorage();
        let cidEncode = location.search.split('cid=')[1];
        let cid = null;
        if (cidEncode) {
            cid = cidEncode;
        }
        if (config) {
            let body = {
                dateFrom: moment(currentPositionDate).format("YYYY-MM-DD"),
                dateTo: moment(currentPositionDate).format("YYYY-MM-DD"),
                billerMerchantKey: config?.billerMerchantKey,
                tcsCid: cid ? cid : config?.isB2BPortal ? userConfig?.cid : config?.cid,
                isVoucher: 2
            };
            POST_BILLER('/public/biller-merchant-tcs-product/list-bydate', body).then((res: any) => {
                if (res?.result) {
                    setTicket(res?.result?.[0]?.listProduct);
                } else {
                    setTicket([]);
                }
            }).catch((error) => {
                console.log(error);
            })
        }
    }

    const getDetailTcsProduct = () => {
        let config = getStorage(CONFIG_STR)
        let userConfig = getUserStorage();
        let cidEncode = location.search.split('cid=')[1];
        let cid = null;
        if (cidEncode) {
            cid = cidEncode;
        }
        POST('/mtcs/index', { cid: cid ? cid : config?.isB2BPortal ? userConfig?.cid : config?.cid })
            .then((tcs) => {
                if (Object.keys(tcs.result).length > 0) {
                    setTcsProduct(tcs.result);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleOpenModal = (index: number) => {
        setIsOpen(true);
        setOpenFocusIndex(index);
    };

    const handleCloseModal = () => setIsOpen(false);

    const renderSection1 = () => {
        return (
            <div className="listingSection__wrap !space-y-6">
                {/* 2 */}
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                    {tcsProduct.name}
                </h2>

                {/* 3 */}
                <div className="flex items-center space-x-4">
                    <StartRating
                        point={tcsProduct.rating}
                        reviewCount={tcsProduct.countReview}
                    />
                    <span>·</span>
                    <span>
                        <i className="las la-map-marker-alt"></i>
                        <span className="ml-1">
                            {' '}
                            {tcsProduct.cityName}, {tcsProduct.provinceName}
                        </span>
                    </span>
                </div>
                <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

                {/* 6 */}
                <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
                    <div className="flex items-center space-x-3 ">
                        <i className=" la la-money text-2xl "></i>
                        <span className="">
                            <span className="inline-block">
                                Rp{' '}
                                {convertNumbThousand(tcsProduct.minPrice) +
                                    ' - ' +
                                    convertNumbThousand(tcsProduct.maxPrice)}
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderSection2 = () => {
        return (
            <div className="listingSection__wrap">
                <h2 className="text-2xl font-semibold">Deskripsi</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="text-neutral-6000 dark:text-neutral-300">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: tcsProduct.tcsConfig.description,
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderTnC = () => {
        return (
            <div className="flex p-5 border-[1px] border-gray-200 rounded-md items-center flex-col justify-between w-full">
                <div onClick={() => setOpenTnc(!openTnc)} className={`flex justify-between items-center cursor-pointer w-full`}>
                    <h2 className="text-2xl font-semibold">Syarat Dan Ketentuan</h2>
                    <div className={`${openTnc ? "rotate-0" : "rotate-180"} duration-300 transition-all w-8 h-8`}>
                        {
                            openTnc ? <ChevronDownIcon /> : <ChevronLeftIcon />
                        }
                    </div>
                </div>
                <div className={`flex-col ${openTnc ? "h-[300px] mt-4" : "h-0"} transition-all duration-700 overflow-y-scroll`}>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="text-neutral-6000 dark:text-neutral-300">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: tcsProduct.tcsConfig.tnc,
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderSidebar = () => {
        return (
            <div>
                {/* FORM */}
                <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-lg">
                    <StayDatesRangeInput
                        wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
                        onChange={(date: any) => {
                            setCurrentPositionDate(date);
                            setTicket(null);
                            if (!tcsProduct?.tcsConfig?.dateFrom || !tcsProduct?.tcsConfig?.dateTo) {
                                setTimeout(() => {
                                    setSelectedDate(moment(date))
                                }, 100);
                            }
                        }}
                        fieldClassName="p-3"
                        defaultValue={selectedDate}
                        anchorDirection={'right'}
                        minDate={tcsProduct?.tcsConfig?.dateFrom ? moment(tcsProduct?.tcsConfig?.dateFrom) : null}
                        maxDate={tcsProduct?.tcsConfig?.dateTo ? moment(tcsProduct?.tcsConfig?.dateTo).add("days", 1) : null}
                        className="nc-ListingDetailDesaWisata__stayDatesRangeInput flex-1"
                    />
                    <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                    {/* <GuestsInput
                        className="nc-ListingDetailDesaWisata__guestsInput flex-1"
                        fieldClassName="p-3"
                        defaultValue={{
                            person: 0,
                        }}
                        onChange={(data) => setPerson(Number(data.person))}
                        hasButtonSubmit={false}
                    /> */}
                </form>

                {/* SUBMIT */}
                {/* <ButtonPrimary onClick={() => handleToCheckout()}>
                    Reserve
                </ButtonPrimary> */}
            </div>
        );
    };

    if (Object.keys(tcsProduct).length === 0) {
        return null;
    }

    return (
        <div
            className={`ListingDetailPage nc-ListingDetailDesaWisata ${className}`}
            data-nc-id="ListingDetailDesaWisata"
        >
            <Helmet>
                <title>{getTitleWebsite()} - Detail</title>
            </Helmet>
            {/* SINGLE HEADER */}
            <>
                <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
                    <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                        <div
                            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                            onClick={() => handleOpenModal(0)}
                        >
                            <NcImage
                                containerClassName="absolute inset-0"
                                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                                src={
                                    tcsProduct.tcsConfig.imageGallery?.length > 0
                                        ? tcsProduct.tcsConfig.imageGallery[0]
                                        : []
                                }
                            />
                            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                        </div>
                        {tcsProduct.tcsConfig.imageGallery
                            ?.filter((_: any, i: any) => i >= 1 && i < 5)
                            .map((item: any, index: any) => (
                                <div
                                    key={index}
                                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? 'hidden sm:block' : ''
                                        }`}
                                >
                                    <NcImage
                                        containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                                        className="object-cover w-full h-full rounded-md sm:rounded-xl "
                                        src={item || ''}
                                    />

                                    {/* OVERLAY */}
                                    <div
                                        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                        onClick={() => handleOpenModal(index + 1)}
                                    />
                                </div>
                            ))}

                        <div
                            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                            onClick={() => handleOpenModal(0)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-5 w-5"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={1.5}
                                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                />
                            </svg>
                            <span className="ml-2 text-neutral-800 text-sm font-medium">
                                Show all photos
                            </span>
                        </div>
                    </div>
                </header>
                {/* MODAL PHOTOS */}
                <ModalPhotos
                    imgs={
                        tcsProduct.tcsConfig.imageGallery?.length > 0
                            ? tcsProduct.tcsConfig.imageGallery
                            : []
                    }
                    isOpen={isOpen}
                    onClose={handleCloseModal}
                    initFocus={openFocusIndex}
                    uniqueClassName="nc-ListingDetailDesaWisata-modalPhotos"
                />
            </>

            {/* MAIn */}
            <main className="container relative z-10 mt-11 flex flex-col">
                {/* CONTENT */}
                <div className="w-full space-y-8 lg:space-y-10">
                    {renderSection1()}
                    {renderSection2()}
                    {renderTnC()}
                </div>
                <div className="flex flex-col lg:flex-row justify-between w-full lg:items-center mt-6">
                    <div className="flex flex-col">
                        <span className="text-neutral-800 text-2xl font-semibold">Paket</span>
                        <span className="text-gray-500 text-md font-medium">
                            {
                                tcsProduct?.tcsConfig?.isOnlyTodayBooking ? moment().format("DD MMM YYYY") : "Pilih Tanggal Kunjungan"
                            }
                        </span>
                    </div>
                    {
                        tcsProduct?.tcsConfig?.isOnlyTodayBooking ? null : <div className="lg:block mt-5 lg:mt-3 w-full lg:w-1/4">
                            <div>{renderSidebar()}</div>
                        </div>
                    }
                </div>
                {
                    tcsProduct?.tcsConfig?.isOnlyTodayBooking ? null :
                        <div className="flex flex-row items-center overflow-x-scroll no-scrollbar">
                            {
                                daysArr?.map((item: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                setCurrentPositionDate(item)
                                                setTicket(null);
                                            }}
                                            className={`py-2 px-6 mt-3 ${currentPositionDate === item ? "bg-blue-100" : "bg-none"} cursor-pointer rounded-md shadow-sm border-[0.1px] mr-2 whitespace-nowrap flex flex-col items-center`}
                                        >
                                            <span className={`text-md font-medium ${currentPositionDate === item ? "text-blue-700" : "text-gray-500"}`}>{moment(item).format("dddd")}</span>
                                            <span className={`text-md font-medium ${currentPositionDate === item ? "text-blue-700" : "text-gray-500"}`}>{moment(item).format("DD MMM")}</span>
                                        </div>
                                    )
                                })
                            }
                            <div>

                            </div>
                        </div>
                }
                <div
                    className="bg-gray-100 p-4 mt-6 rounded-lg"
                >
                    {
                        ticket?.map((item: any) => {
                            return <TicketCard data={item} date={currentPositionDate} />;
                        })
                    }
                </div>
            </main>

            {/* OTHER SECTION */}
            {!isPreviewMode && (
                <div className="container py-5 lg:py-5">
                </div>
            )}
        </div>
    );
};

export default ListingDetailDesaWisata;
