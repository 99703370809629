import { CONFIG_TCS } from "contains/contants";
import moment from "moment";
import { useEffect, useState } from "react";
import {
    deleteLocalStorage,
    deleteUserStorage,
    isLogin,
    setEmailLocalStorage,
    setLogin,
} from 'utils/localStorage';
import jwt_decode from "jwt-decode";

const CheckIsLogin = () => {
    const [isLoginUser, setLoginUser] = useState<any>("first load");
    useEffect(() => {
        getInformationLogin();
    }, [isLogin()])

    const getInformationLogin = () => {
        if (isLogin()) {
            setTimeout(() => {
                let decodeToken: any = jwt_decode(isLogin());
                const time = new Date(0);
                let now = new Date();
                time.setUTCSeconds(decodeToken?.exp);
                let expiredToken = moment(time)
                let nowDate = moment(now)
                if (expiredToken <= nowDate) {
                    setEmailLocalStorage("");
                    deleteLocalStorage(CONFIG_TCS);
                    deleteUserStorage();
                    setLogin(false);
                    setLoginUser(false)
                } else {
                    setLoginUser(true)
                }
            }, 1000);
        } else {
            setLoginUser(false)
        }
    }
    return {
        isLoginUser
    }
}

export default CheckIsLogin;