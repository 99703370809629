const TextField = (props: any) => {
    return (
        <div className="w-full flex flex-col gap-1">
            <span className="text-base text-black">{props?.label}</span>
            <input
                className="w-full rounded-md border-[1px] border-gray-300 h-[40px] px-3"
                {...props}
            />
            {
                props?.errorMessage && <span className="w-full text-xs text-red-500">{props?.errorMessage}</span>
            }
        </div>
    )
}

export default TextField;