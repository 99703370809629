import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";

const InvalidPage: any = ({ message = "Test Message" }) => (
    <div className="nc-Page404">
        <Helmet>
            <title>Invalid Page</title>
        </Helmet>
        <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
            {/* HEADER */}
            <header className="text-center mx-auto space-y-2">
                <div className="w-full flex flex-row justify-center">
                    <img
                        src={require("../../images/invalid-page.png")}
                        style={{
                            width: "60%",
                            height: "60%",
                            objectFit: 'fill'
                        }}
                    />
                </div>
                <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
                    {message.toUpperCase()}{" "}
                </span>
            </header>
        </div>
    </div>
);

export default InvalidPage;
