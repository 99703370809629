import React from "react";

interface QRCodeType {
    closeModal?: any;
    showModalQRTransaction?: boolean;
    children?: React.ReactNode;
    wrapperClassname?: string;
}
const QRCodeModal = ({
    closeModal,
    showModalQRTransaction,
    children,
    wrapperClassname
}: QRCodeType) => {
    const ref: any = React.useRef()
    React.useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (showModalQRTransaction && ref.current && !ref.current.contains(e.target)) {
                closeModal(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [showModalQRTransaction])

    return (
        <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            >
                <div className="w-full h-full mx-auto flex flex-col justify-center items-center">
                    {/*content*/}
                    {/* <div ref={ref} className="w-full h-full flex flex-col items-center justify-center"> */}
                    <div ref={ref} className={wrapperClassname}>
                        {
                            children
                        }
                    </div>
                    {/* </div> */}
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    )
}

export default QRCodeModal;

