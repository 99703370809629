import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Lottie from 'lottie-react';
import { useHistory, useLocation } from 'react-router-dom';
import { decode, encode } from 'js-base64';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import moment from 'moment';
import 'moment/locale/id';
import { GET_REGION, POST, POST_AXIOS, POST_BILLER } from 'utils/apiHelper';
import convertNumbThousand from 'utils/convertNumbThousand';
import { message } from 'utils/message';
import { Dialog, Transition } from '@headlessui/react';
import { deleteRequestInquiry, getMerchantKey, getStorage, getTitleWebsite, getUserStorage, isLogin } from 'utils/localStorage';
import MobileReserveSticky from './MobileReserveSticky';
import LoadingLottie from 'images/lottie/loading.json';
import { Helmet } from 'react-helmet';
import { CONFIG_STR } from 'contains/contants';
import RenderMainDesaWisata from './components/RenderMainDesaWisata';
import UseReservationForm from './hooks/useReservationForm';
// @ts-ignore
import PaymentModal from "payment-no-sdk/build/pages/payment/modal/payment-modal"
import { sha512 } from 'js-sha512';
export interface DesaReservationPageProps {
    className?: string;
}

export interface ProductSelectedType {
    indexProduct?: number;
    value?: number;
}

export interface ProductSelectedProps {
    passenger?: number;
    productSelected?: ProductSelectedType[];
}

const DesaReservationPage: FC<DesaReservationPageProps> = ({ className = '' }) => {
    const location: any = useLocation();
    const router: any = useHistory();
    const ref: any = useRef();
    const [defaultValuePassanger, setDefaultValuePassanger] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [countPerson, setCountPerson] = useState<any>([]);
    const [productTcs, setProductTcs] = useState<any>([]);
    const [productSelected, setProductSelected] = useState<any>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [showAPILoading, setShowAPILoading] = useState<boolean>(false);
    const [passengerForm, setPassengerForm] = useState(null);
    const [regionOptionData, setRegionOptionData] = useState([]);
    const [serviceFeeData, setServiceFeeData] = useState<any>(null);
    const [onsitePayment, setOnsitePayment] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [inquiryData, setInquiryData] = useState<any>(null);
    const formik = UseReservationForm({
        onSubmit: () => {
            let getCountItem: any = [];
            let getCheckPassanger: any = -1;
            productSelected?.map((item: any) => {
                item?.productSelected?.map((res: any) => {
                    if (res.value > 0) {
                        getCheckPassanger = item.passenger;
                    }
                });
                if (getCheckPassanger !== -1) {
                    let checkIsSame = false;
                    getCountItem?.map((item: any) => {
                        if (item.passenger === getCheckPassanger) {
                            checkIsSame = true;
                        }
                    });
                    if (!checkIsSame) {
                        getCountItem.push({
                            passenger: getCheckPassanger,
                        });
                    }
                }
            });
            if (getCountItem.length !== formik.values.persons?.length) {
                message('error', 'Anda belum memilih produk!');
            } else {
                setOpenModal(true);
            }
        }
    });

    const handleClose = () => {
        setOpenModal(false);
        setPassengerForm(null);
    };
    useEffect(() => {
        getOptionRegion()
    }, [])

    useEffect(() => {
        handlePreInquiry()
    }, [productSelected]);

    useEffect(() => {
        if (Object.keys(formik.errors)?.length === 0 && onsitePayment) {
            handleToPayment()
        }
    }, [onsitePayment, formik.errors])

    useEffect(() => {
        if (Object.keys(formik.errors)?.length > 0 && onsitePayment) {
            setOnsitePayment(false)
        }
    }, [onsitePayment, formik.errors])

    React.useEffect(() => {
        if (productTcs.length === 0 && productSelected.length !== 0) {
            getProductTcsInformation();
        }
    }, [productTcs, productSelected]);

    const onsiteButton = useCallback(({ className = "" }: { className?: string }) => {
        if (formik) {
            return (
                <ButtonPrimary
                    type="button"
                    className={className}
                    onClick={() => {
                        let getCountItem: any = [];
                        let getCheckPassanger: any = -1;
                        productSelected?.map((item: any) => {
                            item?.productSelected?.map((res: any) => {
                                if (res.value > 0) {
                                    getCheckPassanger = item.passenger;
                                }
                            });
                            if (getCheckPassanger !== -1) {
                                let checkIsSame = false;
                                getCountItem?.map((item: any) => {
                                    if (item.passenger === getCheckPassanger) {
                                        checkIsSame = true;
                                    }
                                });
                                if (!checkIsSame) {
                                    getCountItem.push({
                                        passenger: getCheckPassanger,
                                    });
                                }
                            }
                        });
                        if (getCountItem.length !== formik.values.persons?.length) {
                            message('error', 'Anda belum memilih produk!');
                        } else {
                            let personsVal: any = []
                            formik.values.persons?.map((res: any) => {
                                personsVal.push({
                                    name: true,
                                    indentityType: true,
                                    indentityNumber: true,
                                    telephone: true,
                                    email: true,
                                    gender: true,
                                    region: true,
                                })
                            })
                            formik.setTouched({ persons: personsVal }, true)
                            if (Object.keys(formik.errors)?.length === 0) {
                                setTimeout(() => {
                                    setOnsitePayment(true)
                                }, 500);
                            }
                        }
                    }}>
                    Bayar di Lokasi
                </ButtonPrimary>
            )
        }
        return null
    }, [formik])

    const handlePreInquiry = async () => {
        let key = location.state && location.state.data;
        let bookingDate = '';
        let user = getUserStorage();
        let config = getStorage(CONFIG_STR);
        if (key) {
            let decodeToJson = JSON.parse(decode(key));
            bookingDate = decodeToJson.date;
            let visitorList: any = [];
            let bookingList: any = [];
            formik.values.persons?.map((_res: any, index: number) => {
                productSelected?.map((item: any) => {
                    item?.productSelected?.map((count: any) => {
                        if (item.passenger === index) {
                            if (count.value > 0) {
                                bookingList.push({
                                    passenger: item.passenger,
                                    item: {
                                        productPrice: count?.item?.b2cPrice,
                                        productVendorCode: count?.item?.productVendorCode,
                                        productQty: count.value,
                                        productFee: count?.item?.serviceFee,
                                        productCode: count?.item?.productCode,
                                        productName: count?.item?.productName,
                                        uniqueProductCode: count?.item?.uniqueProductCode,
                                    },
                                });
                            }
                        }
                    });
                });
                let booking: any = [];
                bookingList?.map((book: any) => {
                    if (book.passenger === index) {
                        booking.push(book.item);
                    }
                });
                visitorList.push({
                    wristbandCode: "",
                    visitorName: formik.values.persons?.[index]?.name || "-",
                    visitorIdType: JSON.parse(formik.values.persons?.[index]?.indentityType)?.value || "-",
                    visitorId: formik.values.persons?.[index]?.indentityNumber || "-",
                    visitorPhone: Number(formik.values.persons?.[index]?.telephone)?.toString(),
                    visitorEmail: formik.values.persons?.[index]?.email || "-",
                    visitorGender: JSON.parse(formik.values.persons?.[index]?.gender)?.value || "-",
                    visitorRegion: JSON.parse(formik.values.persons?.[index]?.region)?.value || "-",
                    visitorBirthDate: "",
                    visitorNationalityCode: "",
                    visitorNationality: "",
                    bookingList: booking,
                });
            });
            let getTotalPrice = 0;
            productSelected?.map((item: any) => {
                item?.productSelected?.map((res: any) => {
                    getTotalPrice += res?.total;
                });
            });
            let body = {
                header: {
                    "merchantKey": decodeToJson.merchantKey,
                    "customerCid": user?.cid || "-",
                    "customerHirarki": user?.hirarki || "-",
                    "customerName": user?.name || "-",
                    "customerEmail": user?.email || "-",
                    "customerID": "-",
                    "customerPhone": user?.phone || "-",
                    "paymentBankName": "-",
                    "paymentBankAccount": "-",
                    "paymentPromoCode": "-",
                    "paymentDiscount": 0,
                    "paymentTotal": getTotalPrice,
                    "paymentFee": 0,
                    "billerMerchantKey": config?.billerMerchantKey,
                    "billerMerchantCode": config?.billerMerchantCode,
                    "billerMerchantName": config?.billerMerchantName
                },
                detail: [
                    {
                        tcCID: decodeToJson.cid,
                        tcVendorCode: decodeToJson.tcVendorCode,
                        tcName: decodeToJson.name,
                        tcHirarki: decodeToJson.hirarki,
                        bookingDateStart: decodeToJson.date + ' 00:00:00',
                        bookingDateEnd: decodeToJson.date + ' 23:59:00',
                        bookingCode: "",
                        visitorList: visitorList,
                    },
                ],
            };
            let timestame = moment().toISOString();
            let signature = "POST" + "||" + "v1/bookingsignature/preinquiry" + "||" + decodeToJson.merchantKey + "||" + sha512(JSON.stringify(body).replace(/\s/g, "")?.toLowerCase()) + "||" + timestame
            let finalSignature = sha512.hmac.hex(user?.cid, signature);
            let checkEmptyProduct = false;
            body.detail?.map((iDetail) => {
                iDetail.visitorList?.map((iVisitor: any) => {
                    if (iVisitor?.bookingList?.length === 0) {
                        checkEmptyProduct = true
                    }
                })
            })
            if (Number(body.header.paymentTotal) > 0 && !checkEmptyProduct) {
                POST('/v1/bookingsignature/preinquiry', body, {
                    "X-MKP-Signature": finalSignature,
                    "X-MKP-Timestamp": timestame
                })
                    .then((preinquiry) => {
                        if (preinquiry?.success) {
                            setServiceFeeData(preinquiry?.result)
                        } else {
                            console.log(preinquiry?.message)
                        }
                    })
                    .catch((error) => {
                        message("error", error)
                    });
            }
        }
    }

    const getProductTcsInformation = async () => {
        let key = location.state && location.state.data;
        if (key) {
            let decodeToJson = JSON.parse(decode(key));
            let config = getStorage(CONFIG_STR);
            if (config) {
                let body = {
                    dateFrom: decodeToJson.date,
                    dateTo: decodeToJson.date,
                    billerMerchantKey: config?.billerMerchantKey,
                    tcsCid: decodeToJson.cid,
                    isVoucher: 1
                };
                POST_BILLER('/public/biller-merchant-tcs-product/list-bydate', body)
                    .then((tcs) => {
                        if (Object.keys(tcs.result).length > 0) {
                            let listProduct: any = [];
                            tcs?.result[0]?.listProduct.map((item: any) => {
                                let image: any = [];
                                let productConfig = {};
                                if (item.productConfig?.imageGallery?.length !== 0) {
                                    item.productConfig.imageGallery?.map((res: any) => {
                                        image.push({
                                            original: res,
                                            thumbnail: res,
                                        });
                                    });
                                }
                                productConfig = {
                                    ...item,
                                    imageGallery: image,
                                };
                                listProduct.push({
                                    ...item,
                                    productConfig: productConfig,
                                    isVoucher: true
                                });
                            });

                            let countClickArr: any = [];
                            let productTemp = [...productSelected];
                            let productTcsArr: any = [];
                            let indexVoucher = 0;
                            productSelected?.[0]?.productSelected?.map((productVoucher: any) => {
                                indexVoucher++;
                                productTcsArr.push({ ...productVoucher.item, isVoucher: false })
                                countClickArr.push({
                                    ...productVoucher
                                })
                            })
                            listProduct?.map((item: any, index: number) => {
                                productSelected?.map(async (res: any, i: any) => {
                                    if (countClickArr[indexVoucher]?.indexProduct !== indexVoucher) {
                                        countClickArr.push({
                                            indexProduct: indexVoucher,
                                            value: 0,
                                            item: item,
                                            total: 0,
                                        });
                                    }
                                    productTemp[i] = {
                                        passenger: i,
                                        productSelected: countClickArr
                                    };
                                    setProductSelected(productTemp);
                                });
                            });
                            setProductTcs([...productTcsArr, ...listProduct]);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
    };
    const getOptionRegion = () => {
        GET_REGION().then(async (res: any) => {
            let data = await res.json()
            setRegionOptionData(data?.result?.map((item: any) => {
                return {
                    label: item?.countryName,
                    value: item?.countryName
                }
            }))
        }).catch((err) => {
            message('error', "Region data not found");
        })
    }

    const identityOption = useMemo(() => {
        let hostname = window.location.host;
        if (hostname === "tiketbatam.mkpmobile.com") {
            return [
                {
                    label: "KTP",
                    value: "KTP"
                }
            ]
        }
        return [
            {
                label: "KTP",
                value: "KTP"
            },
            {
                label: "SIM",
                value: "SIM"
            },
            {
                label: "PASSPORT",
                value: "PASSPORT"
            }
        ];
    }, [window.location.host]);

    const handleToPayment = async () => {
        let key = location.state && location.state.data;
        let bookingDate = '';
        let user = getUserStorage();

        if (key) {
            let decodeToJson = JSON.parse(decode(key));
            let config = getStorage(CONFIG_STR);
            bookingDate = decodeToJson.date;
            let visitorList: any = [];
            let bookingList: any = [];
            formik.values.persons?.map((_res: any, index: number) => {
                productSelected?.map((item: any) => {
                    item?.productSelected?.map((count: any) => {
                        if (item.passenger === index) {
                            if (count.value > 0) {
                                bookingList.push({
                                    passenger: item.passenger,
                                    item: {
                                        productPrice: count?.item?.b2cPrice,
                                        productVendorCode: count?.item?.productVendorCode,
                                        productQty: count.value,
                                        productFee: count?.item?.serviceFee,
                                        productCode: count?.item?.productCode,
                                        productName: count?.item?.productName,
                                        uniqueProductCode: count?.item?.uniqueProductCode
                                    },
                                });
                            }
                        }
                    });
                });
                let booking: any = [];
                bookingList?.map((book: any) => {
                    if (book.passenger === index) {
                        booking.push(book.item);
                    }
                });
                visitorList.push({
                    visitorName: formik.values.persons?.[index]?.name || "-",
                    visitorIdType: JSON.parse(formik.values.persons?.[index]?.indentityType)?.value || "-",
                    visitorId: formik.values.persons?.[index]?.indentityNumber || "-",
                    visitorPhone: Number(formik.values.persons?.[index]?.telephone)?.toString(),
                    visitorEmail: formik.values.persons?.[index]?.email || "-",
                    visitorGender: JSON.parse(formik.values.persons?.[index]?.gender)?.value || "-",
                    visitorRegion: JSON.parse(formik.values.persons?.[index]?.region)?.value || "-",
                    wristbandCode: "",
                    visitorBirthDate: "",
                    visitorNationalityCode: "",
                    visitorNationality: "",
                    bookingList: booking,
                });
            });
            let getTotalPrice = 0;
            let serviceFeeTotal = 0;
            serviceFeeData?.additionalFeeCorporate?.map((sItem: any) => {
                serviceFeeTotal += Number(sItem?.additionalFeeAmount)
            })
            productSelected?.map((item: any) => {
                item?.productSelected?.map((res: any) => {
                    getTotalPrice += res?.total;
                });
            });
            getTotalPrice = getTotalPrice + serviceFeeTotal;
            let body = {
                header: {
                    "merchantKey": decodeToJson.merchantKey,
                    "customerCid": user?.cid,
                    "customerHirarki": user?.hirarki,
                    "customerName": user?.name,
                    "customerEmail": user?.email,
                    "customerID": "",
                    "customerPhone": user?.phone,
                    "paymentBankName": "",
                    "paymentBankAccount": "",
                    "paymentPromoCode": "",
                    "paymentDiscount": 0,
                    "paymentTotal": getTotalPrice,
                    "paymentFee": 0,
                    "billerMerchantKey": config?.billerMerchantKey,
                    "billerMerchantCode": config?.billerMerchantCode,
                    "billerMerchantName": config?.billerMerchantName
                },
                detail: [
                    {
                        tcCID: decodeToJson.cid,
                        tcVendorCode: decodeToJson.tcVendorCode,
                        tcName: decodeToJson.name,
                        tcHirarki: decodeToJson.hirarki,
                        bookingDateStart: decodeToJson.date + ' 00:00:00',
                        bookingDateEnd: decodeToJson.date + ' 23:59:00',
                        bookingCode: "",
                        visitorList: visitorList
                    },
                ],
                isOnsitePayment: onsitePayment ? true : false,
                additionalData: null,
                additionalFee: serviceFeeData?.additionalFee,
                additionalFeeCorporate: serviceFeeData?.additionalFeeCorporate,
                additionalFeeMdr: serviceFeeData?.additionalFeeMdr
            };
            if (serviceFeeData) {
                if (config?.nonSDKPayment) {
                    setInquiryData(body)
                    setOpenPayment(true)
                } else {
                    inquiry(body, bookingDate);
                }
            } else {
                message("error", "Jadwal yang dipilih tidak tersedia")
            }
        }
    };

    const inquiry = async (body: any, bookingDate: string) => {
        // close confirmation dialog
        handleClose();
        // show loading dialog
        setShowAPILoading(true);
        deleteRequestInquiry();
        setTimeout(() => {
            router.push('/checkout', {
                data: encode(JSON.stringify({
                    body: body,
                    bookingDate: bookingDate
                })),
            });
            setShowAPILoading(false);
        }, 1000);
    };

    React.useEffect(() => {
        if (!defaultValuePassanger) {
            setLoading(true);
            let personValueArr: any = []
            let defaultValueTemp = {};
            let key = location.state && location.state.data;
            let config = getStorage(CONFIG_STR)
            if (key) {
                let decodeToJson = JSON.parse(decode(key));
                if (decodeToJson) {
                    let totalPassanger = decodeToJson.person;
                    let personTemp: any = [];
                    for (let i = 0; i < totalPassanger; i++) {
                        personTemp.push(i);
                    }
                    setCountPerson(personTemp);
                    let productSelectTemp = [];
                    for (let i = 0; i < totalPassanger; i++) {
                        productSelectTemp.push({
                            passenger: i,
                            productSelected: [],
                        });
                        personValueArr.push({
                            name: "",
                            indentityType: JSON.stringify({
                                label: config?.enableLevyVoucher ? "PASSPORT" : "KTP",
                                value: config?.enableLevyVoucher ? "PASSPORT" : "KTP"
                            }),
                            indentityNumber: "",
                            telephone: "",
                            email: "",
                            gender: JSON.stringify({
                                label: "Laki-Laki",
                                value: "MALE"
                            }),
                            region: JSON.stringify({
                                label: "INDONESIA",
                                value: "INDONESIA"
                            }),
                        })
                    }

                    defaultValueTemp = {
                        ...defaultValueTemp,
                        customerName: '',
                        telephone: '',
                        email: '',
                    };
                    let productTemp: any = [...productSelectTemp];
                    productTemp.map(async (_res: any) => {
                        let newCount = {};
                        let totalPrice = 1 * decodeToJson?.product?.b2cPrice;
                        newCount = {
                            indexProduct: 0,
                            value: 1,
                            item: decodeToJson?.product,
                            total: totalPrice,
                        };
                        personTemp?.map((_: any, indexCount: number) => {
                            productTemp[indexCount] = {
                                passenger: indexCount,
                                productSelected: [newCount]
                            };
                        })
                    });
                    setProductSelected(productTemp);
                    formik.setValues({
                        persons: personValueArr
                    })
                    setDefaultValuePassanger(defaultValueTemp);
                    setLoading(false);
                }
            }
        }
    }, [defaultValuePassanger, formik.configTcs]);

    const handleSelectedProduct = (passanger: any, indexProduct: any) => {
        setRefresh(true);
        let productTemp: any = [...productSelected];
        let isSame = productTemp.filter(
            (item: any) => item.passenger === passanger
        );
        if (isSame.length > 0) {
            isSame.map(async (res: any) => {
                let productCount = [...res.productSelected];
                // productCount?.map((x, index: number) => {
                //     let dataCount = {};
                //     dataCount = {
                //         indexProduct: index,
                //         value: 0,
                //         item: x.item,
                //         total: 0,
                //     };
                //     productCount[index] = dataCount;
                //     productTemp[passanger] = {
                //         passenger: passanger,
                //         productSelected: productCount,
                //         isVoucher: true
                //     };
                // })
                let isSameProductCount = productCount.filter(
                    (item: any) => item.indexProduct === indexProduct
                );
                if (isSameProductCount.length > 0) {
                    let newCount = {};
                    if (isSameProductCount[0].value === 0) {
                        let totalPrice =
                            (isSameProductCount[0].value + 1) *
                            isSameProductCount[0].item?.b2cPrice;
                        newCount = {
                            indexProduct: isSameProductCount[0].indexProduct,
                            value: isSameProductCount[0].value + 1,
                            item: isSameProductCount[0].item,
                            total: totalPrice,
                        };
                        productCount[indexProduct] = newCount;
                        productTemp[passanger] = {
                            passenger: passanger,
                            productSelected: productCount
                        };
                    } else {
                        let totalPrice = 0;
                        newCount = {
                            indexProduct: isSameProductCount[0].indexProduct,
                            value: 0,
                            item: isSameProductCount[0].item,
                            total: totalPrice,
                        };
                        productCount[indexProduct] = newCount;
                        productTemp[passanger] = {
                            passenger: passanger,
                            productSelected: productCount
                        };
                    }
                    setProductSelected(productTemp);
                    setRefresh(false);
                }
            });
        }
    }

    const handleUploadExcel = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files?.[0]) {
            message("error", "Gagal Upload File!")
        } else {
            let formData = new FormData();
            formData.append('passengerData', e.target.files?.[0]);
            POST_AXIOS('/excel-passenger/read', formData, {
                "Content-Type": "multipart/form-data"
            })
                .then((res: any) => {
                    let personValueArr: any = [];
                    let key = location.state && location.state.data;
                    let config = getStorage(CONFIG_STR)
                    let decodeToJson = JSON.parse(decode(key));
                    if (decodeToJson) {
                        let totalPassanger = decodeToJson.person;
                        for (let i = 0; i < totalPassanger; i++) {
                            res?.result?.passengerData?.map((excel: any, excelIndex: number) => {
                                if (excelIndex === i) {
                                    personValueArr.push({
                                        name: excel?.visitorName,
                                        indentityType: JSON.stringify({
                                            label: config?.enableLevyVoucher ? "PASSPORT" : "KTP",
                                            value: config?.enableLevyVoucher ? "PASSPORT" : "KTP"
                                        }),
                                        indentityNumber: excel?.visitorId,
                                        telephone: excel?.visitorPhone,
                                        email: excel?.visitorEmail,
                                        gender: excel?.visitorGender?.toLowerCase() === "male" ? JSON.stringify({
                                            label: "Laki-Laki",
                                            value: "MALE"
                                        }) : JSON.stringify({
                                            label: "Perempuan",
                                            value: "FEMALE"
                                        }),
                                        region: JSON.stringify({
                                            label: excel?.visitorRegion?.toUpperCase(),
                                            value: excel?.visitorRegion?.toUpperCase()
                                        }),
                                    })
                                }
                            })
                        }
                        formik.setValues({
                            persons: personValueArr
                        })
                        message("success", "Berhasil Upload Data Pengunjung")
                    }
                }).catch((error) => {
                    message("error", "Gagal Upload File!")
                })
        }
    }

    const downloadTemplateExcel = () => {
        if (typeof window !== 'undefined') {
            window.open("https://apipayment.mkpmobile.com/uploads/ote/template-passenger.xlsx", '_blank');
        }
    }

    const renderSidebar = () => {
        let key = location.state && location.state.data;
        let config = getStorage(CONFIG_STR);
        let bookingDate = '';
        if (key) {
            let decodeToJson = JSON.parse(decode(key));
            bookingDate = decodeToJson.date;
        }

        let getTotalPrice = 0;
        let getTotalServicePrice = 0;
        let productGroup: any = [];
        let reGroup: any = []
        let reGroupService: any = []
        productSelected?.map((item: any) => {
            item?.productSelected?.map((res: any) => {
                getTotalPrice += res?.total;
                getTotalServicePrice += res?.item?.serviceFee;
            });
            productGroup.push(...item?.productSelected?.filter((filter: any) => {
                if (filter?.value > 0) {
                    return true
                } else {
                    return false
                }
            }))
        });
        serviceFeeData?.additionalFee?.map((group: any) => {
            let groupIndex = reGroupService.findIndex((val: any) => val?.productName === group?.productName)
            if (groupIndex !== -1) {
                reGroupService[groupIndex] = {
                    ...reGroupService[groupIndex],
                    total: group?.additionalFeeAmount + reGroupService[groupIndex]?.total,
                    count: 1 + reGroupService[groupIndex]?.count
                }
            } else {
                reGroupService.push({
                    ...group,
                    total: group?.additionalFeeAmount,
                    count: 1
                })
            }
        })
        productGroup?.map((group: any) => {
            let groupIndex = reGroup.findIndex((val: any) => val?.indexProduct === group?.indexProduct)
            if (groupIndex !== -1) {
                reGroup[groupIndex] = {
                    ...reGroup[groupIndex],
                    value: group?.value + reGroup[groupIndex]?.value
                }
            } else {
                reGroup.push(group)
            }
        })

        return (
            <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
                <div className="flex flex-col space-y-4">
                    <h3 className="text-2xl font-semibold">Detail Pesanan</h3>
                    <div className="flex flex-row items-center justify-between">
                        <p>Tanggal booking</p>
                        <p>{moment(bookingDate).format('ll')}</p>
                    </div>
                    <>
                        <h3 className="text-xl font-semibold">
                            Data Group
                        </h3>
                        {
                            reGroup?.map((res: any) => {
                                return (
                                    <>
                                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                            <span>
                                                {res?.item?.productName} x {res?.value} Orang
                                            </span>
                                            <span>
                                                Rp{' '}
                                                {convertNumbThousand(
                                                    res?.value * res?.item?.b2cPrice
                                                )}
                                            </span>
                                        </div>
                                    </>
                                );
                            })
                        }
                        {/* <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                    <span>KTM 10K x 1</span>
                    <span>Rp 10.000</span>
                  </div> */}
                    </>

                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                        <span>Total</span>
                        <span>Rp {convertNumbThousand(getTotalPrice)}</span>
                    </div>
                    {
                        reGroupService?.length > 0 && <span className='text-sm text-red-500'>
                            *Sudah termasuk biaya layanan :
                        </span>
                    }
                    {
                        reGroupService?.map((serviceItem: any) => {
                            return (
                                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                    <span className='text-sm text-red-500'>
                                        {serviceItem?.productName} x {serviceItem?.count}
                                    </span>
                                    <span className='text-sm text-red-500'>Rp {convertNumbThousand(serviceItem?.total)}</span>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='w-full flex flex-col gap-4'>
                    <ButtonPrimary type="submit">Pesan</ButtonPrimary>
                    {config?.enableOnsitePayment && onsiteButton({ className: "bg-primary-200 text-primary-6000 hover:bg-primary-100" })}
                </div>
            </div>
        );
    };

    const renderFormik = () => {
        let key = location.state && location.state.data;
        let config = getStorage(CONFIG_STR);
        let bookingDate = '';
        if (key) {
            let decodeToJson = JSON.parse(decode(key));
            bookingDate = decodeToJson.date;
        }
        return (
            <form onSubmit={formik.handleSubmit} method="POST" className="grid grid-cols-1 gap-6">
                <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
                    <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10">
                        {
                            config?.enableLevyVoucher && <div className="w-full flex items-center justify-end mb-4">
                                <div className="flex items-center">
                                    <div className="hidden">
                                        <input
                                            type="file"
                                            accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            ref={ref}
                                            name="upload-excel"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                handleUploadExcel(e)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <ButtonPrimary type="button" onClick={() => ref.current.click()} className="rounded-lg">Import Data Pengunjung</ButtonPrimary>
                                    <div onClick={() => downloadTemplateExcel()}><p className="text-[12px] text-right text-red-600 cursor-pointer">Download Template?</p></div>
                                </div>
                            </div>
                        }
                        {formik.values.persons?.map((item: number, index: number) => (
                            <div key={index}>
                                <RenderMainDesaWisata
                                    regionOptionData={regionOptionData}
                                    handleSelectedProduct={handleSelectedProduct}
                                    productSelected={productSelected}
                                    productTcs={productTcs}
                                    config={formik.configTcs}
                                    formik={formik}
                                    identityOption={identityOption}
                                    index={index}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
                        <div className="sticky top-32">{renderSidebar()}</div>
                    </div>
                </main>
                <MobileReserveSticky
                    productSelected={productSelected}
                    bookingDate={moment(bookingDate).format('ll')}
                    isGroup={true}
                    serviceFeeData={serviceFeeData}
                    onsiteButton={onsiteButton}
                    config={config}
                />
            </form>
        );
    };

    if (loading) {
        return null;
    } else {
        return (
            <div
                className={`nc-DesaReservationPage ${className}`}
                data-nc-id="DesaReservationPage"
            >
                <Helmet>
                    <title>{getTitleWebsite()} - Reservation</title>
                </Helmet>
                {renderFormik()}

                {/* Start: Confirm checkout Dialog */}
                <Transition appear show={openModal} as={React.Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={handleClose}>
                        <Transition.Child
                            as={React.Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={React.Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-center text-gray-900"
                                        >
                                            KONFIRMASI PESANAN
                                        </Dialog.Title>
                                        <div className="mt-10">
                                            <Dialog.Title
                                                as="p"
                                                className="text-lg font-medium leading-6 text-left text-gray-900"
                                            >
                                                Apakah anda sudah yakin dengan pesanan anda?
                                            </Dialog.Title>
                                            <div className="flex flex-row justify-end items-center mt-5">
                                                <ButtonPrimary
                                                    onClick={() => handleClose()}
                                                    type="button"
                                                    className="mr-2"
                                                >
                                                    Batal
                                                </ButtonPrimary>
                                                <ButtonPrimary
                                                    onClick={() => {
                                                        handleToPayment()
                                                    }}
                                                    type="button"
                                                >
                                                    Konfirmasi
                                                </ButtonPrimary>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
                {/* End: Confirm checkout Dialog */}

                {/* Start: Loading checkout Dialog */}
                <Modal
                    open={showAPILoading}
                    onClose={() => { }}
                    closeIcon={<></>}
                    center
                    classNames={{
                        modal: 'rounded-lg',
                    }}
                >
                    <div className="text-lg font-medium leading-6 text-center text-gray-900">
                        <Lottie
                            animationData={LoadingLottie}
                            rendererSettings={{
                                preserveAspectRatio: 'xMidYMid meet',
                            }}
                            className="w-100"
                        />
                        <p className="text-gray-600 text-lg">
                            Sedang memproses pesanan anda, mohon tunggu ....
                        </p>
                    </div>
                </Modal>
                {/* End: Loading checkout Dialog */}
                <PaymentModal
                    open={openPayment}
                    handleClose={() => {
                        handleClose()
                        setOpenPayment(false)
                    }}
                    merchantKey={formik?.configTcs?.merchantKey}
                    inquiryPayload={inquiryData}
                    successCallback={() => {
                        message("success", "Pembayaran berhasil")
                        router.replace('/history');
                    }}
                    isDevelop={process.env.REACT_APP_ENV === 'development' ? true : false}
                    token={isLogin()}
                />
            </div>
        );
    }
};

export default DesaReservationPage;
