import DatePickerComponent from 'components/DatePickerComponent/DatePickerComponent';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Modal } from 'react-responsive-modal';
import { useHistory } from 'react-router-dom';
import Lottie from 'lottie-react';

import EmptyLottie from 'images/lottie/empty.json';
import styles from './History.module.css';
import { POST, POST_LEVY } from 'utils/apiHelper';
import { getStorage, getTitleWebsite, getUserStorage } from 'utils/localStorage';
import { EMAIL } from 'contains/contants';
import { message } from 'utils/message';
import { HistoryLevyDataType } from 'data/types';
import CardLevyHistory from 'components/CardLevyHistory/CardLevyHistory';

export interface HistoryLevyPageProps {
  className?: string;
}

interface PaginationType {
  draw?: number;
  limit?: number;
  offset?: number;
  filter?: boolean;
}
// const historyData = [1];
const HistoryLevyPage: FC<HistoryLevyPageProps> = ({ className = '' }) => {
  const dispatch: any = useDispatch();
  const router: any = useHistory();
  const [historyData, setHistoryData] = useState<HistoryLevyDataType[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [bookingRef, setBookingRef] = useState<string>('');
  const [statusRedeem, setStatusRedeem] =
    useState<string>('');
  const [openDetail, setOpenDetail] = useState<boolean[]>([]);
  const [selectedDate, setSelectedDate] = useState<any>({
    from: null,
    to: null
  });
  const [totalPages, setTotalPages] = useState<any>(0);
  const [offsetData, setOffsetData] = useState(0);

  useEffect(() => {
    getReportingListRedeem({ draw: 1, limit: 5 });
  }, []);

  const nextPage = () => {
    if (offsetData + 1 < totalPages) {
      setOffsetData(offsetData + 1);
      getReportingListRedeem({
        draw: 0,
        limit: 5,
        offset: 5 * (offsetData + 1),
        filter: true,
      });
    }
  };

  const backPage = () => {
    if (offsetData > 0) {
      setOffsetData(offsetData - 1);
      getReportingListRedeem({
        draw: 0,
        limit: 5,
        offset: 5 * (offsetData - 1),
        filter: true,
      });
    }
  };

  const getReportingListRedeem = async ({
    draw = 1,
    limit = 5,
    offset = 0,
    filter = false,
  }: PaginationType) => {
    // let dateNow = new Date();
    // let beforeDate = moment(dateNow).subtract(1, 'days');
    let formatFromDate = selectedDate?.from ? selectedDate?.from?.year + "-" + `${Number(selectedDate?.from?.month) < 10 ? "0" + selectedDate?.from?.month : selectedDate?.from?.month}` + "-" + `${Number(selectedDate?.from?.day) < 10 ? "0" + selectedDate?.from?.day : selectedDate?.from?.day}` : "";
    let formatToDate = selectedDate?.to ? selectedDate?.to?.year + "-" + `${Number(selectedDate?.to?.month) < 10 ? "0" + selectedDate?.to?.month : selectedDate?.to?.month}` + "-" + `${Number(selectedDate?.to?.day) < 10 ? "0" + selectedDate?.to?.day : selectedDate?.to?.day}` : "";
    // if (selectedDate !== undefined || selectedDate !== null) {
    //   formatFromDate = `${selectedDate?.year
    //     .toString()
    //     .padStart(2, '0')}-${selectedDate?.month
    //       .toString()
    //       .padStart(2, '0')}-${selectedDate?.day.toString().padStart(2, '0')} 00:00`;
    //   formatToDate = `${selectedDate?.year.toString().padStart(2, '0')}-${selectedDate?.month
    //     .toString()
    //     .padStart(2, '0')}-${selectedDate?.day.toString().padStart(2, '0')} 23:59`;
    // }

    try {
      let userConfig = getUserStorage();
      setHistoryData([])
      const customerEmail = getStorage(EMAIL);
      if (customerEmail) {
        let body: any = null;
        if (!filter) {
          body = {
            "arrivalDateFrom": '',
            "arrivalDateTo": '',
            "customerEmail": customerEmail,
            "statusRedeem": null,
            "cid": userConfig?.cid,
            "limit": limit,
            "offset": offset,
            "countSummary": true
          };
        } else {
          body = {
            "arrivalDateFrom": formatFromDate,
            "arrivalDateTo": formatToDate,
            "customerEmail": customerEmail,
            "statusRedeem": statusRedeem !== "Status Redeem" ? statusRedeem ? JSON.parse(statusRedeem) : null : null,
            "cid": userConfig?.cid,
            "limit": limit,
            "offset": offset,
            "countSummary": true
          };
        }
        const response = await POST_LEVY('/list', body);
        if (response.success) {
          // if (draw === 1) {
          //   setTotalPages(Math.ceil(response.result?.totalData / limit));
          // }
          let historyArr: any = [];
          let isLastIndex = false;
          setHistoryData(response?.result?.listLevyVoucher)
          setTotalPages(Math.ceil(response?.result?.totalListLevyVoucher / limit))
        } else {
          throw Error(response.message);
        }
      }
    } catch (error: any) {
      message('error', error.message);
    }
  };

  const handleChange = (data: any) => {
    setSelectedDate(data);
  };

  const onConfirmationVoucher = async ({ bookingRef }: { bookingRef: string }) => {
    try {
      const body = {
        "bookingRef": bookingRef
      };

      const response = await POST_LEVY('/redeem-voucher', body);
      if (response.success) {
        message("success", "Success Redeem Levy Voucher");
        window.open(response?.result?.header?.redeemPageUrl, "_blank");
        getReportingListRedeem({ draw: 1, limit: 5 });
      } else {
        throw Error(response.message);
      }
    } catch (error: any) {
      message('error', error.message);
    }
  }

  const handleEditVisitor = async ({ val, callback }: { val: any, callback: Function }) => {
    try {
      const body = {
        "bookingRef": val?.bookingRef,
        "visitorEmail": val?.email,
        "newVisitorEmail": val?.newEmail,
        "name": val?.name,
        "visitorRegion": val?.region ? JSON.parse(val?.region)?.value : "INDONESIA",
        "passportNumber": val?.identityNumber
      };

      const response = await POST_LEVY('/edit-visitor', body);
      if (response.success) {
        message("success", "Success Edit Visitor");
        callback?.();
        getReportingListRedeem({ draw: 1, limit: 5 });
      } else {
        throw Error(response.message);
      }
    } catch (error: any) {
      message('error', error.message);
    }
  }

  return (
    <>
      <div className={`nc-HistoryLevyPage ${className}`} data-nc-id="HistoryLevyPage">
        <Helmet>
          <title>{getTitleWebsite()} - History</title>
        </Helmet>
        <div className="container relative space-y-24 lg:space-y-32">
          <form
            className="listingSection__wrap py-1 w-11/12 mx-auto mt-10"
            onSubmit={(event) => {
              event.preventDefault();
              getReportingListRedeem({ draw: 1, filter: true, offset: 0 });
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 rounded-md my-4 mx-auto w-full">
              <div className="w-full">
                <select
                  value={statusRedeem}
                  onChange={(e) => setStatusRedeem(e.target.value)}
                  className="form-select appearance-none
                                                block
                                                w-full
                                                px-3
                                                text-sm
                                                font-normal
                                                text-slate-300
                                                bg-transparent bg-clip-padding bg-no-repeat
                                                border border-solid border-gray-300
                                                rounded
                                                transition
                                                ease-in-out
                                                m-0
                                                focus:text-gray-700 focus:bg-transparent focus:border-none focus:outline-none"
                  aria-label="Default select example"
                >
                  <option>Status Redeem</option>
                  <option value="true">TRUE</option>
                  <option value="false">FALSE</option>
                </select>
              </div>
              <div className="w-full z-10">
                <div className='relative'>
                  <DatePickerComponent
                    value={selectedDate}
                    onChange={(data: any) => handleChange(data)}
                    inputPicker={styles.inputPicker}
                    position="bottom"
                    placeholder="Tanggal Kedatangan"
                  />
                  {
                    selectedDate && <i className='la la-close absolute right-2 top-[30%] cursor-pointer' onClick={() => setSelectedDate({
                      from: null,
                      to: null
                    })} />
                  }
                </div>
                {/* <input type="text"
                                className="form-control block w-full px-3 text-sm font-normal text-gray-700 bg-transparent bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                placeholder="Select a date" /> */}
              </div>
              <button
                type="submit"
                className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
              >
                Filter
              </button>
            </div>
          </form>
          <div style={{ marginTop: '50px' }}>
            {historyData?.map((item: HistoryLevyDataType, index: number) => (
              <CardLevyHistory
                key={index}
                data={item}
                handleEditVisitor={(val: any, callback: any) => handleEditVisitor({ val: val, callback: callback })}
                handleRedeem={(bookingRef: string) => onConfirmationVoucher({ bookingRef: bookingRef })}
              />
            ))}
          </div>
        </div>
        {historyData?.length === 0 && (
          <div className="mt-25 py-1 w-11/12 mx-auto flex flex-col items-center">
            <Lottie
              animationData={EmptyLottie}
              rendererSettings={{ preserveAspectRatio: 'xMidYMid meet' }}
              className="w-100"
            />
            <p className="text-gray-600 text-lg">
              Riwayat transaksi tidak ditemukan
            </p>
          </div>
        )}
        {historyData?.length > 0 && (
          <div className="scrollbar-hide overflow-x-scroll mx-8 md:mx-36 lg:mx-24 xl:mx-36 mt-2 mb-10 lg:mb-20">
            {/* <Pagination /> */}
            <div className="flex flex-row w-full justify-center items-center mt-5">
              <i
                onClick={() => backPage()}
                className="la la-arrow-alt-circle-left text-4xl cursor-pointer"
                aria-hidden="true"
              ></i>
              <span className="text-xl mx-5">{offsetData + 1}</span>
              <i
                onClick={() => nextPage()}
                className="la la-arrow-alt-circle-right text-4xl cursor-pointer"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        )}
      </div>
      {/* Start: Loading checkout Dialog */}
      <Modal
        modalId={'payment-area'}
        open={showModal}
        onClose={() => { }}
        closeIcon={<></>}
        center
        blockScroll
        classNames={{
          modal: 'rounded-lg w-[93vw] h-[90vh] sm:h-[85vh] md:h-[80vh]',
        }}
      ></Modal>
      {/* End: Loading checkout Dialog */}
    </>
  );
};

export default HistoryLevyPage;
