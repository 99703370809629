import React from "react";
import { QRCodeSVG } from 'qrcode.react';
import moment from "moment";
import QRCodeModal from "components/QRCodeModal/QRCodeModal";
moment.locale("id");

const CollapseShared = ({ item }: any) => {
    const [open, setOpen] = React.useState(false);
    const [showModalQRTransaction, setShowModalQRTransaction] = React.useState(false);
    const [detailQRCode, setDetailQRCode] = React.useState("");
    const [showPersonQRCode, setShowPersonQRCode] = React.useState(false);
    const [personQRCode, setPersonQRCode] = React.useState("");

    return (
        <div className="w-full flex flex-col rounded-lg border-[1px] cursor-pointer px-3 lg:px-8 py-2" style={{ marginTop: "5px" }}>
            <div className='w-full flex flex-row justify-between items-center'>
                <div className='flex flex-col gap-[2px] w-[33%]'>
                    <h6 className="text-sm text-gray-400">Nama</h6>
                    <h6 className="text-base lg:text-lg text-neutral-900 dark:text-neutral-100">{item?.visitorName}</h6>
                </div>
                <div className="w-[33%] flex flex-row justify-end items-center gap-3">
                    {
                        item?.ticketDetail.filter((res: any) => res.vendorTicketCode === "")?.length > 0 &&
                        <i className="la la-qrcode text-[30px] text-black" onClick={() => {
                            setPersonQRCode(item?.qrCode);
                            setShowPersonQRCode(true);
                        }} />

                    }
                    <div className="flex flex-row justify-end items-center" onClick={() => setOpen(!open)}>
                        {
                            open ?
                                <i className='la la-minus text-xl text-black' /> :
                                <i className='la la-plus text-xl text-black' />
                        }
                    </div>
                </div>
            </div>
            {
                open && <div className="mt-2 w-full h-auto py-2">
                    <div className="w-full border-y-[1px] border-t-0 pb-2 flex flex-row">
                        <h6 className="text-base lg:text-md text-gray-400">Detail Pengunjung</h6>
                    </div>
                    {
                        item?.ticketDetail?.map((res: any) => {
                            return (
                                <div className="flex flex-col w-full py-2 border-y-[1px] border-t-0">
                                    <div className="flex flex-row items-center w-full justify-between">
                                        <div className="flex flex-col w-[50%] md:w-[33%]">
                                            <h6 className="text-sm text-gray-400">Nama Produk</h6>
                                            <h6 className="text-base lg:text-lg text-neutral-900 dark:text-neutral-100">{res?.productName}</h6>
                                        </div>
                                        <div className="flex flex-col w-[50%] md:w-[33%] items-end">
                                            {
                                                res?.vendorTicketCode !== "" &&
                                                <button onClick={() => {
                                                    setDetailQRCode(res?.vendorTicketCode)
                                                    setShowModalQRTransaction(true);
                                                }} className="px-2 py-1 text-xs lg:text-sm text-white bg-blue-400 rounded-lg">
                                                    Lihat QR Tiket
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-col mt-2 md:mt-0 gap-2 md:gap-0 md:flex-row items-center w-full justify-between">
                                        <div className="flex flex-col w-full md:w-[33%] items-center md:items-start">
                                            <h6 className="text-sm text-gray-400">Tanggal Mulai</h6>
                                            <h6 className="text-base lg:text-lg text-neutral-900 dark:text-neutral-100">{moment(res?.dateBegin).subtract("hours", 7).format("YYYY-MM-DD HH:mm")}</h6>
                                        </div>
                                        <div className="flex-col w-[33%] items-center hidden md:flex">
                                            <i className="la la-arrow-circle-right text-xl" />
                                        </div>
                                        <div className="flex-col w-[33%] items-center flex md:hidden">
                                            <i className="la la-arrow-alt-circle-down text-xl" />
                                        </div>
                                        <div className="flex flex-col w-full md:w-[33%] items-center md:items-end">
                                            <h6 className="text-sm text-gray-400">Tanggal Kadaluarsa</h6>
                                            <h6 className="text-base lg:text-lg text-neutral-900 dark:text-neutral-100">{moment(res?.dateExpired).subtract("hours", 7).format("YYYY-MM-DD HH:mm")}</h6>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            }
            {
                showModalQRTransaction ?
                    <QRCodeModal
                        closeModal={() => setShowModalQRTransaction(false)}
                        showModalQRTransaction={showModalQRTransaction}
                        wrapperClassname="w-[90%] md:w-[50%] h-[50%] flex flex-col bg-white rounded-md relative items-center justify-center"
                    >
                        <>
                            <div className='absolute top-2 right-2 flex flex-row items-center justify-center'>
                                <i onClick={() => setShowModalQRTransaction(false)} className='la la-close text-[24px] cursor-pointer' />
                            </div>
                            <QRCodeSVG style={{
                                width: "80%",
                                height: "80%"
                            }} value={detailQRCode} />
                            <span className="mt-2">{detailQRCode}</span>
                        </>
                    </QRCodeModal> : null
            }
            {
                showPersonQRCode ?
                    <QRCodeModal
                        closeModal={() => setShowPersonQRCode(false)}
                        showModalQRTransaction={showPersonQRCode}
                        wrapperClassname="w-[90%] md:w-[50%] h-[50%] flex flex-col bg-white rounded-md relative items-center justify-center"
                    >
                        <>
                            <div className='absolute top-2 right-2 flex flex-row items-center justify-center'>
                                <i onClick={() => setShowPersonQRCode(false)} className='la la-close text-[24px] cursor-pointer' />
                            </div>
                            <QRCodeSVG style={{
                                width: "80%",
                                height: "80%"
                            }} value={personQRCode} />
                        </>
                    </QRCodeModal> : null
            }
        </div>
    )
}

export default CollapseShared;