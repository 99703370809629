import { CONFIG_STR } from "contains/contants";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { POST } from "utils/apiHelper";
import { getStorage, getUserStorage } from "utils/localStorage";
import * as yup from "yup";

const initialValues: { persons: any } = {
    persons: []
}
const UseReservationForm = ({ onSubmit }: { onSubmit?: Function }) => {
    const location: any = useLocation();
    const [configTcs, setConfigTcs] = useState<any>(null)
    useEffect(() => {
        getDetailconfigTcs();
    }, []);
    const getDetailconfigTcs = () => {
        let config = getStorage(CONFIG_STR)
        let userConfig = getUserStorage();
        let cidEncode = location.search.split('cid=')[1];
        let cid = null;
        if (cidEncode) {
            cid = cidEncode;
        }
        POST('/mtcs/index', { cid: cid ? cid : config?.isB2BPortal ? userConfig?.cid : config?.cid })
            .then((tcs) => {
                if (Object.keys(tcs.result).length > 0) {
                    setConfigTcs(tcs.result);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const formik = useFormik({
        initialValues,
        onSubmit: () => {
            onSubmit?.()
        },
        validationSchema: yup.object({
            persons: yup.array()
                .of(yup.object().shape(
                    {
                        name: configTcs?.tcsConfig?.visitorName ? yup.string().required("Name is required") : yup.string(),
                        indentityType: configTcs?.tcsConfig?.visitorIdType ? yup.string().required("Indentity is required") : yup.string(),
                        indentityNumber: configTcs?.tcsConfig?.visitorId ? yup.string().required("Indentity Number is required") : yup.string(),
                        telephone: configTcs?.tcsConfig?.visitorPhone ? yup.string().required("Telephone is required") : yup.string(),
                        email: configTcs?.tcsConfig?.visitorEmail ? yup.string().required("Email is required") : yup.string(),
                        gender: configTcs?.tcsConfig?.visitorGender ? yup.string().required("Gender is required") : yup.string(),
                        region: configTcs?.tcsConfig?.visitorRegion ? yup.string().required("Region is required") : yup.string(),
                    }
                )),
        }),
        enableReinitialize: true,
    });
    return {
        ...formik,
        configTcs
    }
}

export default UseReservationForm;