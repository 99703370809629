import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import ModalPhotos from 'containers/ListingDetailPage/ModalPhotos';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';
import NcImage from 'shared/NcImage/NcImage';
import { getTitleWebsite } from 'utils/localStorage';
import DatePicker, { utils, Calendar } from '@hassanmojab/react-modern-calendar-datepicker';
import checkFileExt from 'utils/checkFileExt';
import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
import SectionGridFeaturePlaces from './SectionGridFeaturePlaces';
import SectionHowItWork from 'components/SectionHowItWork/SectionHowItWork';
import Heading from 'components/Heading/Heading';
import { useEffect, useState } from 'react';
import moment from 'moment';

interface ProductViewOrientation {
    settings: any;
    config: any;
    tcsProduct: any;
    handleOpenModal: Function;
    isOpen: boolean;
    handleCloseModal: any;
    openFocusIndex: number;
    setOpenPassenger: Function;
    openPassenger: boolean;
    passenger: any;
    wrapperRef: any;
    handleDecrement: Function;
    handleIncrement: Function;
    selectedDay: any;
    setSelectedDay: any;
    renderCustomInput: any;
    renderCustomRangeInput: any;
    handleToCheckout: Function;
    renderSection1: any;
    renderSection2: any;
    tcsEvent: any;
    handleToGroupCheckout: Function;
    isPersonal: boolean;
    setPersonal: Function;
    setPassenger: Function;
}
const OnlyOneProductView = ({
    settings,
    config,
    tcsProduct,
    handleOpenModal,
    isOpen,
    handleCloseModal,
    openFocusIndex,
    setOpenPassenger,
    openPassenger,
    passenger,
    wrapperRef,
    handleDecrement,
    handleIncrement,
    selectedDay,
    setSelectedDay,
    renderCustomInput,
    renderCustomRangeInput,
    handleToCheckout,
    renderSection1,
    renderSection2,
    tcsEvent,
    handleToGroupCheckout,
    isPersonal,
    setPersonal,
    setPassenger
}: ProductViewOrientation) => {
    const dateNow = Date.now();

    const defaultFrom = {
        year: Number(moment(dateNow).format("YYYY")),
        month: Number(moment(dateNow).format("MM")),
        day: Number(moment(dateNow).format("DD")),
    };
    const defaultTo = {
        year: Number(moment(dateNow).format("YYYY")),
        month: Number(moment(dateNow).format("MM")),
        day: Number(moment(dateNow).format("DD")),
    };
    const defaultRange = {
        from: defaultFrom,
        to: defaultTo,
    };

    useEffect(() => {
        if (!isPersonal) {
            setSelectedDay(defaultRange);
        } else {
            setSelectedDay(null);
        }
    }, [isPersonal]);

    return (
        <div className="nc-PageHome3 relative overflow-hidden">
            <Helmet>
                <title>{getTitleWebsite()}</title>
            </Helmet>
            {/* GLASSMOPHIN */}
            <BgGlassmorphism />

            {/* SECTION HERO */}
            <Slider {...settings}>
                {config?.imageBanner.map((item: any) => {
                    const fileType = checkFileExt(item);

                    if (fileType === 'image') {
                        return (
                            <img
                                src={item}
                                alt=""
                                className="object-fill h-[35vh] md:h-[50vh] lg:h-[80vh]"
                            />
                        );
                    } else if (fileType === 'video') {
                        return (
                            <div>
                                <video
                                    width="100%"
                                    height="auto"
                                    autoPlay={true}
                                    muted={true}
                                    loop={true}
                                    playsInline={true}
                                    id="myVideo"
                                    data-ll-status="loaded"
                                >
                                    <source data-src={item} src={item} />
                                </video>
                            </div>
                        );
                    }

                    return <></>;
                })}
            </Slider>
            <div className="container relative space-y-24 mb-24 mt-0 sm:mt-10">
                {/* SECTION */}
                <div className="relative py-8 md:py-16" style={{
                    marginTop: "1rem"
                }}>
                    <>
                        <header className={
                            tcsProduct?.[0]?.galleryImgs?.length > 1 ?
                                "container 2xl:px-14 rounded-md sm:rounded-xl flex flex-row justify-center" : "hidden"
                        }>
                            <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2 w-full md:w-[90%]">
                                <div
                                    className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                                    onClick={() => handleOpenModal(0)}
                                >
                                    <NcImage
                                        containerClassName="absolute inset-0"
                                        className="object-cover w-full h-full rounded-md sm:rounded-xl"
                                        src={
                                            tcsProduct?.[0]?.galleryImgs?.length > 0
                                                ? tcsProduct?.[0]?.galleryImgs[0]
                                                : []
                                        }
                                    />
                                    <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
                                </div>
                                {tcsProduct?.[0]?.galleryImgs
                                    ?.filter((_: any, i: any) => i >= 1 && i < 5)
                                    .map((item: any, index: any) => (
                                        <div
                                            key={index}
                                            className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? 'hidden sm:block' : ''
                                                }`}
                                        >
                                            <NcImage
                                                containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                                                className="object-cover w-full h-full rounded-md sm:rounded-xl "
                                                src={item || ''}
                                            />

                                            {/* OVERLAY */}
                                            <div
                                                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                                                onClick={() => handleOpenModal(index + 1)}
                                            />
                                        </div>
                                    ))}

                                <div
                                    className="absolute hidden md:flex md:items-center md:justify-center left-3 top-2 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                                    onClick={() => handleOpenModal(0)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={1.5}
                                            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                        />
                                    </svg>
                                    <span className="ml-2 text-neutral-800 text-sm font-medium">
                                        Show all photos
                                    </span>
                                </div>
                            </div>
                        </header>
                        {/* MODAL PHOTOS */}
                        <ModalPhotos
                            imgs={
                                tcsProduct?.[0]?.galleryImgs?.length > 0
                                    ? tcsProduct?.[0]?.galleryImgs
                                    : []
                            }
                            isOpen={isOpen}
                            onClose={handleCloseModal}
                            initFocus={openFocusIndex}
                            uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
                        />
                    </>
                    <div className="relative md:absolute md:left-[5%] -bottom-10 w-full md:w-[90%] py-8 flex flex-row justify-center items-center">
                        <form
                            className="rounded-2xl px-4 md:py-4 w-11/12 mx-auto md:mt-10 z-[10] bg-white shadow-lg relative"
                            onSubmit={(event) => {
                                event.preventDefault();
                            }}
                        >
                            <div className="bg-white px-1 py-1 relative lg:absolute top-[-2rem] flex flex-row gap-3">
                                <button onClick={() => setPersonal(true)} className={`${isPersonal ? "bg-blue-800" : "bg-gray-400"} px-3 py-2 hover:bg-blue-800 text-white font-bold text-sm lg:text-base`}>
                                    Personal Booking
                                </button>
                                <button onClick={() => setPersonal(false)} className={`${isPersonal ? "bg-gray-400" : "bg-blue-800"} px-5 py-2 hover:bg-blue-800 text-white font-bold text-sm lg:text-base`}>
                                    Group Booking
                                </button>
                            </div>
                            <div className="flex flex-col md:flex-row justify-around px-3 rounded-md my-4 mt-[-1rem] lg:my-4 mx-auto w-full">
                                <div className="w-full my-1 md:w-1/2 mr-2 relative">
                                    <div className="flex flex-row items-center gap-2 cursor-pointer">
                                        <i className="las la-users text-[30px]"></i>
                                        <div className="flex flex-col justify-between gap-1">
                                            <div className="text-base text-black font-bold">Pengunjung</div>
                                            <div className="text-sm text-gray-400 font-thin" onClick={() => setOpenPassenger(true)}>
                                                {
                                                    passenger !== 0 ? passenger + " Pengunjung" : "Tambahkan Pengunjung"
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        openPassenger && isPersonal &&
                                        <div ref={wrapperRef} className='w-full gap-4 md:gap-8 z-[1000] absolute -bottom-24 h-[120px] md:h-[80px] border-solid border rounded-xl p-4 md:p-0 flex flex-row flex-wrap justify-center items-center shadow-md bg-white'>
                                            {
                                                [1, 2, 3, 4, 5]?.map((value: any, index: number) => {
                                                    return (
                                                        <button onClick={() => {
                                                            setPassenger(value)
                                                            setOpenPassenger(false);
                                                        }} key={index} className='py-2 px-5 bg-blue-700 rounded-md text-white'>
                                                            {value}
                                                        </button>
                                                    );
                                                })
                                            }
                                        </div>
                                    }
                                    {
                                        openPassenger && !isPersonal &&
                                        <div ref={wrapperRef} className='w-full gap-4 md:gap-8 z-[1000] absolute -bottom-24 h-[100px] md:h-[80px] border-solid border rounded-xl flex flex-col md:flex-row justify-center items-center shadow-md bg-white'>
                                            <div className="text-sm font-bold text-black">Pengunjung</div>
                                            <div className='flex flex-row gap-4'>
                                                <i onClick={() => handleDecrement()} className="las la-minus-circle text-[40px] cursor-pointer"></i>
                                                <input
                                                    value={passenger}
                                                    onChange={(e) => {
                                                        if (Number(e.target.value) >= 0) {
                                                            setPassenger(e.target.value)
                                                        }
                                                    }}
                                                    type="number"
                                                    className='text-sm text-black w-28 border-none text-center pl-[1rem] md:pl-[1.8rem]'
                                                />
                                                <i onClick={() => handleIncrement()} className="las la-plus-circle text-[40px] cursor-pointer"></i>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="w-full flex flex-col my-1 md:w-1/2 mr-2">
                                    {
                                        isPersonal ?
                                            <DatePicker
                                                value={selectedDay}
                                                onChange={setSelectedDay}
                                                renderInput={renderCustomInput}
                                                shouldHighlightWeekends
                                                minimumDate={utils("en").getToday()}
                                            /> :
                                            <DatePicker
                                                value={selectedDay}
                                                onChange={setSelectedDay}
                                                renderInput={renderCustomRangeInput}
                                                shouldHighlightWeekends
                                            />
                                    }
                                </div>
                                <button
                                    onClick={() => {
                                        if (isPersonal) {
                                            handleToCheckout()
                                        } else {
                                            handleToGroupCheckout();
                                        }
                                    }}
                                    type="submit"
                                    className="inline-block mt-5 md:mt-0 w-full md:w-16 rounded-full bg-blue-600 text-white font-medium text-xs leading-tight uppercase shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                                >
                                    <i className="las la-search text-[30px]"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <main className="container relative z-0 flex flex-col mt-0 md:mt-[1rem] lg:flex-row" style={{
                    marginTop: "1rem"
                }}>
                    {/* CONTENT */}
                    <div className="w-full space-y-8">
                        {renderSection1()}
                        {renderSection2()}
                        {/* {renderSection4()} */}
                        {/* {renderSectionCheckIndate()} */}
                        {/* {renderSection5()} */}
                        {/* {renderSection6()} */}
                        {/* {renderSection7()} */}
                        {/* {renderSection8()} */}
                    </div>
                </main>
                {
                    tcsEvent?.length > 0 ?
                        <div className="relative py-8 md:py-16" style={{
                            marginTop: "4rem"
                        }}>
                            <BackgroundSection />
                            <SectionGridFeaturePlaces
                                tcsEvent={tcsEvent.slice(0, 4)}
                                tabs={["Event"]}
                                currentTabs="Event"
                            />
                        </div> : null
                }
                <SectionHowItWork />
                <div>
                    <Heading isCenter desc="">
                        Tentang Kami
                    </Heading>
                    <span className="block text-neutral-500 dark:text-neutral-400 text-center">
                        {config?.about}
                    </span>
                </div>
                {/* SECTION */}
                {/* <SectionSubscribe2 /> */}
            </div >
        </div >
    )
}

export default OnlyOneProductView;