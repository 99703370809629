import { FC } from 'react';
import Sheet from 'react-modal-sheet';
import convertNumbThousand from 'utils/convertNumbThousand';

export interface BottomSlidingPaneReserveProps {
  className?: string;
  isOpen: boolean;
  onClose: VoidFunction;
  productSelected: any[];
  bookingDate: string;
  isGroup: boolean;
  serviceFeeData?: any;
}

const BottomSlidingPaneReserve: FC<BottomSlidingPaneReserveProps> = ({
  className = '',
  isOpen,
  onClose,
  productSelected,
  bookingDate,
  isGroup,
  serviceFeeData
}) => {
  const renderHeader = () => {
    return (
      <div className="m-3">
        <p className="text-base font-body text-center text-black">
          Berikut merupakan detail pesanan anda
        </p>
      </div>
    );
  };

  const renderContent = () => {
    let getTotalPrice = 0;
    let getTotalServicePrice = 0;
    productSelected?.map((item: any) => {
      item?.productSelected?.map((res: any) => {
        getTotalPrice += res?.total;
        getTotalServicePrice += res?.item?.serviceFee;
      });
    });

    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col space-y-4 mx-5">
          <div className="flex flex-row items-center justify-between">
            <p>Tanggal booking</p>
            <p>{bookingDate}</p>
          </div>
          {productSelected?.map((item: any, index: any) => {
            return (
              <>
                <h3 className="text-xl font-semibold">
                  Pengunjung {index + 1}
                </h3>
                {item?.productSelected?.map((res: any) => {
                  if (res.value > 0) {
                    return (
                      <>
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                          <span>
                            {res?.item?.productName} x {res?.value}
                          </span>
                          <span>
                            Rp{' '}
                            {convertNumbThousand(
                              res?.value * res?.item?.b2cPrice
                            )}
                          </span>
                        </div>
                      </>
                    );
                  }
                })}
              </>
            );
          })}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>Rp {convertNumbThousand(getTotalPrice)}</span>
          </div>
        </div>
      </div>
    );
  };

  const renderGroupContent = () => {
    let getTotalPrice = 0;
    let getTotalServicePrice = 0;
    let productGroup: any = [];
    let reGroup: any = []
    let reGroupService: any = []
    productSelected?.map((item: any) => {
      item?.productSelected?.map((res: any) => {
        getTotalPrice += res?.total;
        getTotalServicePrice += res?.item?.serviceFee;
      });
      productGroup.push(...item?.productSelected?.filter((filter: any) => {
        if (filter?.value > 0) {
          return true
        } else {
          return false
        }
      }))
    });
    serviceFeeData?.additionalFee?.map((group: any) => {
      let groupIndex = reGroupService.findIndex((val: any) => val?.productName === group?.productName)
      if (groupIndex !== -1) {
        reGroupService[groupIndex] = {
          ...reGroupService[groupIndex],
          total: group?.additionalFeeAmount + reGroupService[groupIndex]?.total,
          count: 1 + reGroupService[groupIndex]?.count
        }
      } else {
        reGroupService.push({
          ...group,
          total: group?.additionalFeeAmount,
          count: 1
        })
      }
    })
    productGroup?.map((group: any) => {
      let groupIndex = reGroup.findIndex((val: any) => val?.indexProduct === group?.indexProduct)
      if (groupIndex !== -1) {
        reGroup[groupIndex] = {
          ...reGroup[groupIndex],
          value: group?.value + reGroup[groupIndex]?.value
        }
      } else {
        reGroup.push(group)
      }
    })

    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col space-y-4 mx-5">
          <div className="flex flex-row items-center justify-between">
            <p>Tanggal booking</p>
            <p>{bookingDate}</p>
          </div>
          {/* {productSelected?.map((item: any, index: any) => {
            return ( */}
          <>
            <h3 className="text-xl font-semibold">
              Data Group
            </h3>
            {
              reGroup?.map((res: any) => {
                return (
                  <>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>
                        {res?.item?.productName} x {res?.value} Orang
                      </span>
                      <span>
                        Rp{' '}
                        {convertNumbThousand(
                          res?.value * res?.item?.b2cPrice
                        )}
                      </span>
                    </div>
                  </>
                );
              })
            }
          </>
          {/* );
          })} */}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span>Rp {convertNumbThousand(getTotalPrice)}</span>
          </div>
          {
            reGroupService?.length > 0 && <span className='text-sm text-red-500'>
              *Sudah termasuk biaya layanan :
            </span>
          }
          {
            reGroupService?.map((serviceItem: any) => {
              return (
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span className='text-sm text-red-500'>
                    {serviceItem?.productName} x {serviceItem?.count}
                  </span>
                  <span className='text-sm text-red-500'>Rp {convertNumbThousand(serviceItem?.total)}</span>
                </div>
              )
            })
          }
        </div>
      </div>
    );
  };

  return (
    <Sheet
      rootId="root"
      isOpen={isOpen}
      onClose={onClose}
      snapPoints={[700, 300, 100, 0]}
      initialSnap={0}
      className='relative'
    >
      <Sheet.Container>
        <div className='absolute top-2 right-2 z-20' onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <Sheet.Header />
        <Sheet.Header>{renderHeader()}</Sheet.Header>

        <Sheet.Content>{isGroup ? renderGroupContent() : renderContent()}</Sheet.Content>
      </Sheet.Container>

      <Sheet.Backdrop />
    </Sheet>
  );
};

export default BottomSlidingPaneReserve;