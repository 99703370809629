import { Dialog, Transition } from '@headlessui/react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import CommentListing from 'components/CommentListing/CommentListing';
import FiveStartIconForRate from 'components/FiveStartIconForRate/FiveStartIconForRate';
import GuestsInput from 'components/HeroSearchForm/GuestsInput';
import StayDatesRangeInput from 'components/HeroSearchForm/StayDatesRangeInput';
import SectionSubscribe2 from 'components/SectionSubscribe2/SectionSubscribe2';
import StartRating from 'components/StartRating/StartRating';
import useWindowSize from 'hooks/useWindowResize';
import { decode, encode } from 'js-base64';
import React, { FC, Fragment, useState } from 'react';
import { FocusedInputShape } from 'react-dates';
import { useHistory, useLocation } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonCircle from 'shared/Button/ButtonCircle';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import Input from 'shared/Input/Input';
import NcImage from 'shared/NcImage/NcImage';
import { POST } from 'utils/apiHelper';
import convertNumbThousand from 'utils/convertNumbThousand';
import { message } from 'utils/message';
import LikeSaveBtns from './LikeSaveBtns';
import MobileFooterSticky from './MobileFooterSticky';
import ModalPhotos from './ModalPhotos';
const getSrc = require('get-src');
const momentPropTypes = require('react-moment-proptypes');

export interface ListingEventProductPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const PHOTOS: string[] = [
  'https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
  'https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
  'https://images.pexels.com/photos/2677398/pexels-photo-2677398.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
];

const Amenities_demos = [
  { name: 'la-key', icon: 'la-key' },
  { name: 'la-luggage-cart', icon: 'la-luggage-cart' },
  { name: 'la-shower', icon: 'la-shower' },
  { name: 'la-smoking', icon: 'la-smoking' },
  { name: 'la-snowflake', icon: 'la-snowflake' },
  { name: 'la-spa', icon: 'la-spa' },
  { name: 'la-suitcase', icon: 'la-suitcase' },
  { name: 'la-suitcase-rolling', icon: 'la-suitcase-rolling' },
  { name: 'la-swimmer', icon: 'la-swimmer' },
  { name: 'la-swimming-pool', icon: 'la-swimming-pool' },
  { name: 'la-tv', icon: 'la-tv' },
  { name: 'la-umbrella-beach', icon: 'la-umbrella-beach' },
  { name: 'la-utensils', icon: 'la-utensils' },
  { name: 'la-wheelchair', icon: 'la-wheelchair' },
  { name: 'la-wifi', icon: 'la-wifi' },
  { name: 'la-baby-carriage', icon: 'la-baby-carriage' },
  { name: 'la-bath', icon: 'la-bath' },
  { name: 'la-bed', icon: 'la-bed' },
  { name: 'la-briefcase', icon: 'la-briefcase' },
  { name: 'la-car', icon: 'la-car' },
  { name: 'la-cocktail', icon: 'la-cocktail' },
  { name: 'la-coffee', icon: 'la-coffee' },
  { name: 'la-concierge-bell', icon: 'la-concierge-bell' },
  { name: 'la-dice', icon: 'la-dice' },
  { name: 'la-dumbbell', icon: 'la-dumbbell' },
  { name: 'la-hot-tub', icon: 'la-hot-tub' },
  { name: 'la-infinity', icon: 'la-infinity' },
];

const ListingEventProductPage: FC<ListingEventProductPageProps> = ({
  className = '',
  isPreviewMode,
}) => {
  const location = useLocation();
  const router = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [tcsProduct, setTcsProduct] = useState<any>({});
  const [map, setMap] = React.useState<any>('');
  const [selectedDate, setSelectedDate] = useState<
    typeof momentPropTypes.momentObj | null
  >(null);
  const [date, setDate] = React.useState('');
  const [person, setPerson] = React.useState<number>(0);
  const [focusedInputSectionCheckDate, setFocusedInputSectionCheckDate] =
    useState<FocusedInputShape>('startDate');
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const windowSize = useWindowSize();

  React.useEffect(() => {
    if (Object.keys(tcsProduct).length === 0) {
      getDetailTcsProduct();
    }
  }, [tcsProduct]);

  const handleToCheckout = () => {
    if (date === '' || person === 0) {
      message('error', 'Please fill field correctly');
    } else {
      let data = {
        date: date,
        person: person,
        cid: tcsProduct?.cid,
        merchantKey: tcsProduct?.merchantKey,
        name: tcsProduct?.name,
        tcVendorCode: tcsProduct?.tcVendorCode,
        hirarki: tcsProduct?.hirarki,
      };
      let dataToEncode = encode(JSON.stringify(data));
      router.push('/reservation', {
        data: dataToEncode,
      });
    }
  };

  const getDetailTcsProduct = () => {
    let cid = decode(location.search.split('key=')[1]);
    POST('/mtcs/index', { cid: cid })
      .then((tcs) => {
        if (Object.keys(tcs.result).length > 0) {
          setTcsProduct(tcs.result);
          let getMap = getSrc(tcs.result.tcsConfig?.embedMap);
          setMap(getMap);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={tcsProduct.tcsCategoryName} />
          <LikeSaveBtns />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {tcsProduct.name}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating
            point={tcsProduct.rating}
            reviewCount={tcsProduct.countReview}
          />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">
              {' '}
              {tcsProduct.cityName}, {tcsProduct.provinceName}
            </span>
          </span>
        </div>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" la la-money text-2xl "></i>
            <span className="">
              <span className="hidden sm:inline-block">
                Rp{' '}
                {convertNumbThousand(tcsProduct.minPrice) +
                  ' - ' +
                  convertNumbThousand(tcsProduct.maxPrice)}
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Place information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span
            dangerouslySetInnerHTML={{
              __html: tcsProduct.tcsConfig.description,
            }}
          />
        </div>
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Room Rates </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Friday - Sunday</span>
              <span>$219</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Rent by month</span>
              <span>-8.34 %</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>1 night</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Max number of nights</span>
              <span>90 nights</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const renderSectionCheckIndate = () => {
  //   return (
  //     <div className="listingSection__wrap overflow-hidden">
  //       {/* HEADING */}
  //       <div>
  //         <h2 className="text-2xl font-semibold">Availability</h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Prices may increase on weekends or holidays
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* CONTENT */}

  //       <div className="listingSection__wrap__DayPickerRangeController flow-root">
  //         <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
  //           <DayPickerRangeController
  //             startDate={selectedDate.startDate}
  //             endDate={selectedDate.endDate}
  //             onDatesChange={(date) => setSelectedDate(date)}
  //             focusedInput={focusedInputSectionCheckDate}
  //             onFocusChange={(focusedInput) =>
  //               setFocusedInputSectionCheckDate(focusedInput || "startDate")
  //             }
  //             initialVisibleMonth={null}
  //             numberOfMonths={windowSize.width < 1280 ? 1 : 2}
  //             daySize={getDaySize()}
  //             hideKeyboardShortcutsPanel={false}
  //             isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="##">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {tcsProduct.address}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <iframe
              className="w-full"
              src={map}
              height="100%"
              allowFullScreen={false}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        {/* <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            Rp {convertNumbThousand(tcsProduct.minPrice) + " - " + convertNumbThousand(tcsProduct.maxPrice)}
          </span>
          <StartRating point={tcsProduct.rating} reviewCount={tcsProduct.countReview} />
        </div> */}

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2"
            onChange={(date: any) => setDate(date)}
            fieldClassName="p-3"
            defaultValue={selectedDate}
            anchorDirection={'right'}
            className="nc-ListingEventProductPage__stayDatesRangeInput flex-1"
          />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            className="nc-ListingEventProductPage__guestsInput flex-1"
            fieldClassName="p-3"
            defaultValue={{
              person: 0,
            }}
            onChange={(data) => setPerson(Number(data.person))}
            hasButtonSubmit={false}
          />
        </form>

        {/* SUBMIT */}
        <ButtonPrimary onClick={() => handleToCheckout()}>
          Reserve
        </ButtonPrimary>
      </div>
    );
  };

  if (Object.keys(tcsProduct).length === 0) {
    return null;
  }

  return (
    <div
      className={`ListingDetailPage nc-ListingEventProductPage ${className}`}
      data-nc-id="ListingEventProductPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={
                  tcsProduct.tcsConfig.imageGallery?.length > 0
                    ? tcsProduct.tcsConfig.imageGallery[0]
                    : []
                }
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {tcsProduct.tcsConfig.imageGallery
              ?.filter((_: any, i: any) => i >= 1 && i < 5)
              .map((item: any, index: any) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 3 ? 'hidden sm:block' : ''
                  }`}
                >
                  <NcImage
                    containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl "
                    src={item || ''}
                  />

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={() => handleOpenModal(index + 1)}
                  />
                </div>
              ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={
            tcsProduct.tcsConfig.imageGallery?.length > 0
              ? tcsProduct.tcsConfig.imageGallery
              : []
          }
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingEventProductPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {/* {renderSection4()} */}
          {/* {renderSectionCheckIndate()} */}
          {/* {renderSection5()} */}
          {/* {renderSection6()} */}
          {renderSection7()}
          {/* {renderSection8()} */}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-32">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && <MobileFooterSticky tcsProducts={tcsProduct} />}

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-24 lg:py-32">
          {/* SECTION 1 */}
          {/* <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Explore by types of stays"
              subHeading="Explore houses based on 10 types of stays"
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName={"ListingEventProductPage1"}
            />
          </div> */}

          {/* SECTION */}
          <SectionSubscribe2 className="pt-24 lg:pt-32" />
        </div>
      )}
    </div>
  );
};

export default ListingEventProductPage;
