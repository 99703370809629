import { Field } from 'formik';
import { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import convertNumbThousand from 'utils/convertNumbThousand';
import { shortString } from 'utils/shortString';
import styles from '../Reservation.module.css';
import { getStorage } from 'utils/localStorage';
import { CONFIG_STR } from 'contains/contants';
import SelectField from 'components/SelectField';
import TextField from 'components/TextField';

const RenderMainDesaWisata = ({
    index,
    formik,
    identityOption,
    regionOptionData,
    handleSelectedProduct,
    productSelected,
    productTcs,
    config
}: {
    index: number,
    formik: any,
    identityOption: any,
    regionOptionData: any,
    productTcs: any,
    productSelected: any,
    handleSelectedProduct: Function,
    config: any
}) => {
    const [openProductVoucher, setOpenProductVoucher] = useState(false);
    const [isLevy, setLevy] = useState(false);
    useEffect(() => {
        let config = getStorage(CONFIG_STR);
        setLevy(config?.enableLevyVoucher || false);
    }, []);
    return (
        <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8 mb-5">
            <h2 className="text-2xl lg:text-3xl font-semibold">
                Pengunjung ke-{index + 1}
            </h2>
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <main className="container relative z-10 flex flex-col lg:flex-row ">
                {/* CONTENT */}
                <div className="w-full mb-8">
                    <div className={`${config?.tcsConfig?.visitorName ? "block" : "hidden"}`}>
                        <TextField
                            label="Nama"
                            placeholder="Nama"
                            id={`persons.${index}.name`}
                            name={`persons.${index}.name`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.persons?.[index]?.name?.toString()}
                            errorMessage={checkValidationError(formik, index, "name")}
                        />
                    </div>
                    <div className={`${config?.tcsConfig?.visitorIdType ? "block" : "hidden"}`}>
                        <SelectField
                            label="Jenis Identitas"
                            placeholder="Jenis Identitas"
                            data={identityOption}
                            disabled={isLevy ? true : false}
                            value={formik.values.persons?.[index]?.indentityType}
                            onChange={(e: any) => {
                                let values: any = formik.values.persons;
                                values[index] = {
                                    ...values?.[index],
                                    indentityType: e.target.value
                                }
                                formik.setFieldValue("persons", values)
                            }}
                            errorMessage={checkValidationError(formik, index, "indentityType")}
                        />
                    </div>
                    <div className={`${config?.tcsConfig?.visitorId ? "block" : "hidden"}`}>
                        <TextField
                            label="Nomor Identitas"
                            placeholder="Nomor Identitas"
                            id={`persons.${index}.indentityNumber`}
                            name={`persons.${index}.indentityNumber`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.persons?.[index]?.indentityNumber?.toString()}
                            errorMessage={checkValidationError(formik, index, "indentityNumber")}
                        />
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700 mt-8 mb-5"></div>
                    <div className={`${config?.tcsConfig?.visitorPhone ? "block" : "hidden"}`}>
                        <TextField
                            label="Telephone"
                            placeholder="Telephone"
                            type="number"
                            id={`persons.${index}.telephone`}
                            name={`persons.${index}.telephone`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.persons?.[index]?.telephone?.toString()}
                            errorMessage={checkValidationError(formik, index, "telephone")}
                        />
                    </div>
                    <div className={`${config?.tcsConfig?.visitorEmail ? "block" : "hidden"}`}>
                        <TextField
                            label="Alamat E-mail"
                            placeholder="Nama Email"
                            id={`persons.${index}.email`}
                            name={`persons.${index}.email`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.persons?.[index]?.email?.toString()}
                            errorMessage={checkValidationError(formik, index, "email")}
                        />
                    </div>
                    <div className={`${config?.tcsConfig?.visitorGender ? "block" : "hidden"}`}>
                        <SelectField
                            label="Jenis Kelamin"
                            placeholder="Jenis Kelamin"
                            data={[
                                {
                                    label: "Laki-Laki",
                                    value: "MALE"
                                },
                                {
                                    label: "Perempuan",
                                    value: "FEMALE"
                                }
                            ]}
                            value={formik.values.persons?.[index]?.gender}
                            onChange={(e: any) => {
                                let values: any = formik.values.persons;
                                values[index] = {
                                    ...values?.[index],
                                    gender: e.target.value
                                }
                                formik.setFieldValue("persons", values)
                            }}
                            errorMessage={checkValidationError(formik, index, "gender")}
                        />
                    </div>
                    <div className={`${config?.tcsConfig?.visitorRegion ? "block" : "hidden"}`}>
                        <SelectField
                            label="Wilayah"
                            placeholder="Wilayah"
                            data={regionOptionData}
                            value={formik.values.persons?.[index]?.region}
                            onChange={(e: any) => {
                                let values: any = formik.values.persons;
                                values[index] = {
                                    ...values?.[index],
                                    region: e.target.value
                                }
                                formik.setFieldValue("persons", values)
                            }}
                            errorMessage={checkValidationError(formik, index, "region")}
                        />
                    </div>
                    {
                        isLevy && <label className="relative inline-flex items-center cursor-pointer mt-4">
                            <input type="checkbox" onChange={(e) => {
                                let voucherArr = [];
                                productTcs?.map((item: any) => {
                                    if (item?.isVoucher) {
                                        voucherArr.push(item);
                                    }
                                })
                                if (voucherArr?.length === 1) {
                                    handleSelectedProduct(index, 1);
                                }
                                setOpenProductVoucher(e.target.checked)
                            }} checked={openProductVoucher} className="sr-only peer" />
                            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ms-3 text-sm font-medium text-gray-900 ml-2">Produk Voucher</span>
                        </label>
                    }
                    {
                        <div className={`${openProductVoucher ? "block transition-all duration-100" : "hidden"}`}>
                            <div className="grid grid-cols-2 lg:grid-cols-3">
                                {productTcs?.map((item: any, indexProduct: number) => {
                                    return (
                                        <div className={`
                                            ${productSelected[index]?.productSelected[indexProduct]?.value === 1 ?
                                                "m-2 flex flex-col relative border-solid border-[3px] border-green-400" :
                                                "m-2 flex flex-col relative"} ${item?.isVoucher ? "block" : "hidden"}
                                        `} key={indexProduct} onClick={() => handleSelectedProduct(index, indexProduct)}>
                                            {
                                                item.productConfig?.imageGallery?.length > 0 ?
                                                    <ImageGallery
                                                        items={item.productConfig?.imageGallery}
                                                        // autoPlay={true}
                                                        showBullets={true}
                                                        showThumbnails={false}
                                                        showPlayButton={false}
                                                        showNav={false}
                                                        additionalClass={productSelected[index]?.productSelected[indexProduct]?.value === 1 ? styles.imageGalleryActive : styles.imageGallery}
                                                    /> : <img
                                                        src='/assets/no-image.jpeg'
                                                        style={{
                                                            width: "100%",
                                                            height: "150px",
                                                            objectFit: "contain"
                                                        }}
                                                        alt='no-image'
                                                    />
                                            }
                                            <div className="w-full h-9 text-[0.6rem] lg:text-xs flex flex-row bg-slate-500 py-2 justify-center items-center">
                                                <div className="text-white text-center" style={{ lineHeight: "15px" }}>
                                                    {shortString(item?.productName, 30)} - Rp{' '}{convertNumbThousand(item?.b2cPrice)}
                                                </div>
                                            </div>
                                            {
                                                productSelected[index]?.productSelected[indexProduct]?.value === 1 &&
                                                <div className="absolute w-full h-full flex items-center justify-center z-10">
                                                    <span className="mr-5">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="rgb(22 163 74 / var(--tw-border-opacity))" className="w-24 h-24">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    }
                </div>
            </main>
        </div>
    );
};

const checkValidationError = (formik: any, index: number, value: string) => {
    if (index >= 0) {
        return formik.touched.persons?.[index]?.[value] && formik.errors.persons?.[index]?.[value];
    } else {
        return formik.touched?.[value] && formik.errors?.[value]
    }
}

export default RenderMainDesaWisata;