import { Base64 } from "js-base64";
import { isLogin, setEmailLocalStorage, setLogin } from "./localStorage";
import axios from "axios";

export const POST = async (pathname: string, data: any, header: any = {}) => {
    let url = process.env.REACT_APP_API_URL + pathname;
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': isLogin() ? "Bearer " + isLogin() : '',
            ...header
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });
    if (response.ok) {
        return response.json();
    } else {
        if (response.statusText === "Unauthorized") {
            setLogin(false);
            setEmailLocalStorage("");
            window.location.reload();
        }
        return response.json();
    }
}

export const POST_LEVY = async (pathname: string, data: any, header: any = {}) => {
    let url = process.env.REACT_APP_API_LEVY + pathname;
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': isLogin() ? "Bearer " + isLogin() : '',
            ...header
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });
    if (response.ok) {
        return response.json();
    } else {
        if (response.statusText === "Unauthorized") {
            setLogin(false);
            setEmailLocalStorage("");
            window.location.reload();
        }
        return response.json();
    }
}

export const GET = async (pathname: string, header: any = {}) => {
    let url = process.env.REACT_APP_API_URL + pathname;
    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': isLogin() ? "Bearer " + isLogin() : '',
            ...header
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    return response.json();
}

export const GET_RECEIPT = async (pathname: string, header: any = {}) => {
    let url = process.env.REACT_APP_API_BILLER + pathname;
    let authString = `${process.env.REACT_APP_PUBLIC_USERNAME}:${process.env.REACT_APP_PUBLIC_PASSWORD}`
    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Basic " + Base64.btoa(authString),
            ...header
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    return response.json();
}

export const POST_BILLER = async (pathname: string, data: any, header: any = {}) => {
    let url = process.env.REACT_APP_API_BILLER + pathname;
    let authString = `${process.env.REACT_APP_PUBLIC_USERNAME}:${process.env.REACT_APP_PUBLIC_PASSWORD}`
    const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Basic " + Base64.btoa(authString),
            ...header
        },
        body: JSON.stringify(data),
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    return response.json();
}

export const GET_VALIDATE_CAPTCHA = async (pathname: string, header: any = {}) => {
    let url = process.env.REACT_APP_API_BILLER + pathname;
    let authString = `${process.env.REACT_APP_PUBLIC_USERNAME}:${process.env.REACT_APP_PUBLIC_PASSWORD}`
    const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': "Basic " + Base64.btoa(authString),
            ...header
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer'
    });
    return response.json();
}

export const GET_REGION = () => {
    return new Promise((resolve, reject) => {
        fetch("https://apipayment.mkpmobile.com/provincecity/public/province-city/country").then((res) => {
            resolve(res);
        }).catch((err) => {
            reject(err);
        })
    });
}

export const POST_AXIOS = async (pathname: string, data: any, header: any = {}) => {
    return new Promise((resolve, reject) => {
        let url = process.env.REACT_APP_API_URL + pathname;
        axios({
            url: url,
            method: "POST",
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': isLogin() ? "Bearer " + isLogin() : '',
                ...header
            }
        }).then((res) => {
            if (res.data) {
                resolve(res?.data)
            }
        }).catch((error) => {
            reject(error)
        })
    })
}