import React, { FC, useEffect, useState } from 'react';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import {
  HistoryLevyDataType, VisitorLevyType,
} from 'data/types';
import { GET_REGION, POST_LEVY } from 'utils/apiHelper';
import Button from 'shared/Button/Button';
import { Dialog, Transition } from '@headlessui/react';
import TextField from 'components/TextField';
import { useFormik } from 'formik';
import * as yup from "yup"
import SelectField from 'components/SelectField';
import { message } from 'utils/message';

export interface CardLevyHistoryProps {
  handleEditVisitor?: any;
  data?: HistoryLevyDataType;
  handleRedeem?: Function;
}

const initialValues = {
  name: "",
  identityNumber: "",
  email: "",
  newEmail: "",
  region: "",
  bookingRef: ""
}

const CardLevyHistory: FC<CardLevyHistoryProps> = ({
  handleEditVisitor,
  data,
  handleRedeem
}) => {
  const [openDetail, setOpenDetail] = useState(false)
  const [visitorList, setVisitorList] = useState<VisitorLevyType[]>([]);
  const [isEdit, setEdit] = useState(false);
  const [offset, setOffset] = useState(0);
  const [regionOptionData, setRegionOptionData] = useState([]);
  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      handleEditVisitor(values, () => {
        setEdit(false)
        resetForm()
      })
    },
    validationSchema: yup.object({
      name: yup.string().required("Name is required"),
      identityNumber: yup.string().required("Indentity Number is required"),
      newEmail: yup.string().required("Email is required"),
      region: yup.string().required("Region is required"),
    }),
    enableReinitialize: true,
  });
  useEffect(() => {
    if (openDetail) {
      getInformationDetailRedeem();
      getOptionRegion()
    }
  }, [openDetail, data]);

  const getOptionRegion = () => {
    GET_REGION().then(async (res: any) => {
      let data = await res.json()
      setRegionOptionData(data?.result?.map((item: any) => {
        return {
          label: item?.countryName,
          value: item?.countryName
        }
      }))
    }).catch((err) => {
      message('error', "Region data not found");
    })
  }

  const getInformationDetailRedeem = async () => {
    let body = {
      "bookingRef": data?.bookingRef,
      "limit": 100000,
      "offset": offset
    }
    const response = await POST_LEVY('/visitorlist', body);
    if (response.success) {
      // if (draw === 1) {
      //   setTotalPages(Math.ceil(response.result?.totalData / limit));
      // }
      let historyArr: any = [];
      let isLastIndex = false;
      setVisitorList(response?.result?.visitorList)
    } else {
      throw Error(response.message);
    }
  }

  return (
    <>
      <div className="flex flex-col w-full p-6 rounded-md shadow-md border-[0.1px] mb-4 gap-1">
        <div className='flex flex-col md:flex-row items-start md:items-center justify-between gap-1'>
          <LabelLevy title="Booking Ref" value={data?.bookingRef} />
          <div className='flex items-center gap-1'>
            <div onClick={() => {
              if (data?.statusRedeem) {
                window.open(data?.redeemPageUrl, "_blank");
              }
            }} className={`font-bold rounded-md ${data?.statusRedeem ? "bg-green-200 border-2 cursor-pointer border-green-300 text-green-500" : "bg-red-200 border-2 border-red-300 text-red-500"} py-1 px-4`}>
              {data?.statusRedeem ? "REDEEM SUCCESS" : "NOT REDEEM"}
            </div>
            {!data?.statusRedeem && <Button onClick={() => handleRedeem?.(data?.bookingRef)} className='bg-blue-700 py-2 md:py-[6px]  text-white rounded-md'>Redeem Now</Button>}
          </div>
        </div>
        <div className='flex flex-col md:flex-row items-start md:items-center  justify-between gap-1'>
          <LabelLevy title="Levy Voucher" value={data?.levyVoucher || "-"} />
          <LabelLevy title="Status Generate" className='text-left md:text-right' value={data?.statusGenerate || "-"} />
        </div>
        <div className='flex flex-col md:flex-row items-start md:items-center justify-between gap-1'>
          <LabelLevy title="Tanggal Booking" value={data?.bookingDate} />
          <LabelLevy title="Tanggal Expired" className="text-left md:text-right" value={data?.expiredDate || "-"} />
        </div>
        <div className='flex flex-col md:flex-row items-start md:items-center  justify-between gap-1'>
          <LabelLevy title="Jumlah Pengunjung" value={data?.visitorQty?.toString()} />
        </div>
        <button
          onClick={() => setOpenDetail(!openDetail)}
          className="flex w-full mt-5 justify-between rounded-lg py-2 text-left text-sm font-medium text-blue-400 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75"
        >
          <span>Detail</span>
          <ChevronUpIcon
            className={`${openDetail ? 'rotate-180 transition-all duration-500' : ''
              } h-5 w-5 text-blue-400`}
          />
        </button>
        <div className={`${openDetail ? "flex transition-colors duration-500" : "hidden"} w-full flex-col`}>
          {
            visitorList?.map((visitor, index) => {
              return (
                <div key={index} className="flex flex-col w-full border-b-[0.5px] pb-4">
                  <div className='flex flex-col md:flex-row items-start md:items-center justify-between gap-1'>
                    <LabelLevy title="Nama" value={visitor?.name} />
                    <div className='flex items-center gap-1'>
                      <div className={`font-bold rounded-md ${visitor?.statusRedeem ? "bg-green-200 border-2 border-green-300 text-green-500" : "bg-red-200 border-2 border-red-300 text-red-500"} py-1 px-4`}>
                        {visitor?.statusRedeem ? "REDEEM SUCCESS" : "NOT REDEEM"}
                      </div>
                      {!visitor?.statusRedeem && <Button onClick={() => {
                        setEdit(true)
                        formik.setValues({
                          email: visitor?.email,
                          identityNumber: visitor?.passportNumber,
                          name: visitor?.name,
                          region: JSON.stringify({
                            label: visitor?.countryName,
                            value: visitor?.countryName
                          }),
                          newEmail: visitor?.email,
                          bookingRef: data?.bookingRef || ""
                        })
                      }} className='bg-blue-700 py-2 md:py-[6px]  text-white rounded-md'>Edit Data</Button>}
                    </div>
                  </div>
                  <div className='flex flex-col md:flex-row items-start md:items-center justify-between gap-1'>
                    <LabelLevy title="Email" value={visitor?.email} />
                    <LabelLevy title="Tanggal Kedatangan" className="text-left md:text-right" value={visitor?.arrivalDate || "-"} />
                  </div>
                  <div className='flex flex-col md:flex-row items-start md:items-center justify-between gap-1'>
                    <LabelLevy title="No Passport" value={visitor?.passportNumber} />
                    <LabelLevy title="Asal Negara" className="text-left md:text-right" value={`${visitor?.countryName}` || "-"} />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <Transition appear show={isEdit} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setEdit(false)}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-h-fit max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-center text-gray-900"
                  >
                    EDIT DATA
                  </Dialog.Title>
                  <form onSubmit={formik.handleSubmit} method="POST">
                    <div className="mt-5 flex flex-col gap-2">
                      <TextField
                        label="Nama"
                        placeholder="Nama"
                        id={`name`}
                        name={`name`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        errorMessage={formik.touched?.name && formik.errors?.name}
                      />
                      <TextField
                        label="Email"
                        placeholder="Email"
                        id={`newEmail`}
                        name={`newEmail`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.newEmail}
                        errorMessage={formik.touched?.newEmail && formik.errors?.newEmail}
                      />
                      <TextField
                        label="No. Passport"
                        placeholder="No. Passport"
                        id={`identityNumber`}
                        name={`identityNumber`}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.identityNumber}
                        errorMessage={formik.touched?.identityNumber && formik.errors?.identityNumber}
                      />
                      <SelectField
                        label="Wilayah"
                        placeholder="Wilayah"
                        data={regionOptionData}
                        value={formik.values.region}
                        onChange={(e: any) => {
                          formik.setFieldValue("region", e.target.value)
                        }}
                        errorMessage={formik.touched?.region && formik.errors?.region}
                      />
                    </div>
                    <div className="flex justify-end w-full mt-4">
                      <Button type="submit" className="bg-blue-600 py-[4px] px-2 text-white rounded-md">Edit</Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

const LabelLevy = ({ title, value, className }: { title: string, value?: string, className?: string }) => {
  return (
    <div className="flex flex-col gap-1">
      <p className={`text-base md:text-lg font-semibold ${className}`}>{title}</p>
      <p className={`text-sm md:text-base font-normal ${className}`}>{value}</p>
    </div>
  )
}

export default CardLevyHistory;
