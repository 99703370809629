const SelectField = (props: any) => {
    return (
        <div className="w-full flex flex-col gap-1">
            <span className="text-base text-black">{props?.label}</span>
            <select className="w-full rounded-md border-[1px] border-gray-300 h-[40px] px-3" {...props}>
                <option value="" disabled selected>{props?.placeholder}</option>
                {
                    props?.data?.map((item: any, index: number) => {
                        return <option key={index} value={JSON.stringify(item)}>{item?.label ? item?.label : item}</option>
                    })
                }
            </select>
            {
                props?.errorMessage && <span className="w-full text-xs text-red-500">{props?.errorMessage}</span>
            }
        </div>
    )
}

export default SelectField;